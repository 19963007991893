import colors from "../../../../../../../../utils/colors";
import userUtil from "../../../../../../../../utils/user.util";

import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";

import { useAppState } from "../../../../../appState.context";
import { useNavigate } from "react-router-dom";

export default ({ ctx }) => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const languageCtx = useLanguage();

  const handleNoClicked = () => {
    ctx.popItem();
  };

  const handleYesClicked = async () => {
    try {
      await appStateCtx.user.signout();
      // remove jan 2025
      navigate("/");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <>
      <div className="text-center p-4">
        <div className="fs-2">
          {languageCtx.getStringFromId("sureYouWantToLogOut")}
        </div>

        {userUtil.isRegistered(appStateCtx.user.get()) && (
          <div style={{ fontSize: 16, marginTop: 10 }}>
            {languageCtx.getStringFromId("youCanLogBackInUsingYourEmail")}
          </div>
        )}
        {!userUtil.isRegistered(appStateCtx.user.get()) && (
          <div style={{ fontSize: 16, marginTop: 10, color: colors.red }}>
            {languageCtx.getStringFromId(
              "You are not registered. You cannot log back in after logging out."
            )}
          </div>
        )}
        <div className="mt-3">
          <button className="btn btn-gray" onClick={handleNoClicked}>
            {languageCtx.getStringFromId("no")}
          </button>
          <button className="btn btn-blue ms-1" onClick={handleYesClicked}>
            {languageCtx.getStringFromId("yes")}
          </button>
        </div>
      </div>
      {/* 
      <RegisterUserModal
        show={showUnregisteredUserModal}
        setShow={setShowUnregisteredUserModal}
      /> */}
    </>
  );
};
