const util = {
  set: (name, value) => {
    localStorage.setItem(name, value);
  },
  get: (name) => {
    return localStorage.getItem(name);
  },
  remove: (name) => {
    localStorage.removeItem(name);
  },
  // isAvailable: () => {
  //   try {
  //     return !!window.localStorage;
  //   } catch {
  //     return false;
  //   }
  // },
};

module.exports = util;
