import { AppStateContextProvider } from "./appState.context";
import Nav from "./nav";

export default () => {
  return (
    <AppStateContextProvider>
      <Nav />
    </AppStateContextProvider>
  );
};
