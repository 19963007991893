import {
  faGear,
  faImages,
  faLink,
  faPersonCirclePlus,
  faPlus,
  faRightLeft,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import userUtil from "../../../../../../utils/user.util";

import { useLanguage } from "../../../../../language.context";
import ButtonMenuControl from "../../../../../controls/buttonmenu.control";

import { useAppState } from "../../../appState.context";

import AlbumControl from "./album/control";
import AppControl from "./app/control";
import CreateAlbumEnternameControl from "./createalbum/enterName.control";
import ChangeAlbumControl from "./changealbum.control";
import JoinAlbumControl from "./joinalbum.control";
import InviteControl from "./invite.control";
import WanttoregisterControl from "./wanttoregister.control";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("Create album"),
      icon: faPlus,
      onClick: () => {
        if (userUtil.isRegistered(appStateCtx.user.get())) {
          ctx.pushItem(
            languageCtx.getIdFromId("Create album"),
            (ctx) => <CreateAlbumEnternameControl ctx={ctx} />,
            () => {
              ctx.createAlbum.reset();
            }
          );
        } else {
          ctx.pushItem(
            languageCtx.getIdFromId("Register user"),
            (ctx) => <WanttoregisterControl ctx={ctx} />,
            () => {
              ctx.createAlbum.reset();
            }
          );
        }
      },
      visible: () => true,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Change album"),
      icon: faRightLeft,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Change album"), (ctx) => (
          <ChangeAlbumControl ctx={ctx} />
        ));
      },
      visible: () => true,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Join album"),
      icon: faLink,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Join album"), (ctx) => (
          <JoinAlbumControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Invite people"),
      icon: faPersonCirclePlus,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Invite people"), (ctx) => (
          <InviteControl ctx={ctx} />
        ));
      },
      visible: () => true,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Album settings"),
      icon: faImages,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Album settings"), (ctx) => (
          <AlbumControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("App settings"),
      icon: faGlobe,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("App settings"), (ctx) => (
          <AppControl ctx={ctx} />
        ));
      },
    },
  ];

  return <ButtonMenuControl items={items} />;
};
