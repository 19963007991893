const util = {
  formatString: function (s) {
    var args = arguments;
    return s.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[parseInt(number) + 1] != "undefined"
        ? args[parseInt(number) + 1]
        : match;
    });
  },

  textToHtml: (text) => {
    if (!text) return "";
    return text.replaceAll("\n", "<br/>");
  },
};

export default util;
