import { useEffect, useState } from "react";
import { faUserXmark, faUserCheck } from "@fortawesome/free-solid-svg-icons";

import albumUtil from "../../../../../../../../utils/album.util";

import { useException } from "../../../../../../../exception.context";
import { useLanguage } from "../../../../../../../language.context";
import SectionListMenuControl from "../../../../../../../controls/sectionlistmenu.control";

import { useAppState } from "../../../../../appState.context";

import MemberControl from "./member.control";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const languageCtx = useLanguage();

  const [items, setItems] = useState([]);

  const refresh = async () => {
    try {
      const users = await appStateCtx.apiServerClient.album.getUsers(
        appStateCtx.album.get()._id
      );

      users.sort((a, b) => a.name.localeCompare(b.name));

      const items = [
        {
          title: languageCtx.getIdFromId("albumOwner"),
          data: users
            .filter((u) => albumUtil.isUserOwner(appStateCtx.album.get(), u))
            .map((u) => {
              return {
                id: u._id,
                name: u.name,
                sub: u.email,
                icon: faUserCheck,
                onClick: () => {
                  ctx.pushItem(languageCtx.getIdFromId("member"), (ctx) => (
                    <MemberControl ctx={ctx} userId={u._id} />
                  ));
                },
              };
            }),
        },
        {
          title: languageCtx.getIdFromId("albumMembers"),
          data: users
            .filter((u) => !albumUtil.isUserOwner(appStateCtx.album.get(), u))
            .map((u) => {
              return {
                id: u._id,
                name: `${u.name}${
                  u.blocked ? languageCtx.getStringFromId(" (blocked)") : ""
                }`,
                sub: u.email,
                icon: u.blocked ? faUserXmark : faUserCheck,
                onClick: () => {
                  ctx.pushItem(languageCtx.getIdFromId("member"), (ctx) => (
                    <MemberControl ctx={ctx} userId={u._id} />
                  ));
                },
              };
            }),
        },
      ];

      setItems(items);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="p-1">
      <SectionListMenuControl items={items} />
    </div>
  );
};
