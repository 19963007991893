import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import cssUtil from "./utils/css.util";

cssUtil.insertVariablesIntoCss();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
