import environmentUtil from "./environment.util";

const settings = {
  company: {
    name: "Fotisima",
    city: "Escobedo",
    country: "Mexico",
    countryCode: "MX",
    emails: {
      contact: "admin@innoware.mx",
      helpdesk: "contact@fotisima.mx",
    },
    openingHours: "Mon - Fri: 9:00 - 18:00",
    web: "https://innoware.mx",
    facebookUrl: "https://www.facebook.com/profile.php?id=61565938874046",
    instagramUrl: "https://www.instagram.com/fotisima.mx/",
    youtubeUrl: "https://www.youtube.com/@Fotisima",
  },
  dateShortFormat: "yyyy-MM-dd",
  dateLongFormat: "yyyy MMM dd",
  monthLongFormat: "yyyy MMM",
  monthShortFormat: "yyyy-MM",
  //cart: false,
  colors: {
    primary: "#05386b",
  },
  topBar: {
    height: 65,
  },
  stripe: {
    publishableKey: environmentUtil.getValue(
      "pk_live_51N45IsF0gzeorS1qemDv4pDjtyqE92R6F9RfEa0Y442zo8V7lbosUATleLFru4mvXpGsKfqstef7VP0h9sxeycvb00lUyUJ1CC",
      "pk_live_51N45IsF0gzeorS1qemDv4pDjtyqE92R6F9RfEa0Y442zo8V7lbosUATleLFru4mvXpGsKfqstef7VP0h9sxeycvb00lUyUJ1CC",
      "pk_test_51N45IsF0gzeorS1q6pCwIP7NgTETXsc8YsrzIOe2WG9Gk6qgMIfmVwOuMifjH4sBYI207v1VH0c9yQUQ7gNH8I9i00UFvHtYs6"
    ),
  },
};

export default settings;
