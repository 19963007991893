import { Helmet } from "react-helmet-async";

import { useLanguage } from "../../../language.context";
import HeaderControl from "../../../controls/header2.control";
import FooterControl from "../../../controls/footer.control";

export default () => {
  const languageCtx = useLanguage();

  return (
    <div>
      <Helmet>
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <HeaderControl />
      <div className="container page privacypolicy_termsofservice">
        <div
          dangerouslySetInnerHTML={{
            __html: languageCtx.getTermsOfService().html,
          }}
        ></div>
      </div>
      <FooterControl />
    </div>
  );
};
