import React, { createContext, useState, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import HeaderControl from "../../../controls/header2.control";

import { useAppState } from "../appState.context";

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();

  const [name, setName] = useState("");
  const [processType, setProcessType] = useState("");
  const [termsOfService, setTermsOfService] = useState();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (appStateCtx.user.get()) {
      if (appStateCtx.album.get()) {
        navigate(`/app/albums/${appStateCtx.album.get()._id}`);
      } else {
        navigate(`/app/albums`);
      }
    }
  }, []);

  const initialValue = {
    name,
    setName,

    processType,
    setProcessType,

    termsOfService,
    setTermsOfService,

    privacyPolicy,
    setPrivacyPolicy,

    email,
    setEmail,
  };

  return (
    <>
      <HeaderControl />
      <authContext.Provider value={initialValue}>
        {children}
      </authContext.Provider>
    </>
  );
};
