import environmentUtil from "./environment.util";

const url = environmentUtil.getValue(
  "https://api1.fotisima.mx",
  "https://api1.fotisima.mx",
  window.location.origin
  //"http://localhost:3000"
);

const serverUrl = `${url}/api/v1`;

//console.log("user serverUrl", serverUrl);

class ApiServerAffiliateClient {
  #token = undefined;

  #getUrl = (path) => {
    const endpointUrl = `${serverUrl}${path}`;

    return endpointUrl;
  };

  getToken = () => {
    return this.#token;
  };

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw json;
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, authorization, body) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authorization) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (body) {
      options.body = JSON.stringify(body);
    }

    const endpointUrl = this.#getUrl(path);

    //console.log(endpointUrl);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    //console.log("result", result);

    return result;
  };

  affiliate = {
    signin: async (email, password, language) => {
      const affiliate = await this.#post("/affiliate/signin", false, {
        email,
        password,
        language,
      });

      this.#token = affiliate.token;

      return affiliate;
    },
    updatePassword: async (password) => {
      await this.#post("/affiliate/updatePassword", true, {
        password,
      });
    },
    signinByToken: async (token, language) => {
      const affiliate = await this.#post("/affiliate/signinByToken", false, {
        token,
        language,
      });

      this.#token = affiliate.token;

      return affiliate;
    },
    getGiftcards: async () => {
      const giftcards = await this.#post("/affiliate/getGiftcards", true);

      return giftcards;
    },
    sendGiftcardToClient: async (giftcardId) => {
      const giftcards = await this.#post(
        "/affiliate/sendGiftcardToClient",
        true,
        {
          giftcardId,
        }
      );

      return giftcards;
    },
    getPackages: async (type) => {
      const packages = await this.#post("/affiliate/getPackages", true, {
        type,
      });

      return packages;
    },
    getGiftcardByReceipt: async (receipt) => {
      const packages = await this.#post(
        "/affiliate/getGiftcardByReceipt",
        true,
        {
          receipt,
        }
      );

      return packages;
    },
    createPayInvoicePaymentIntent: async (invoiceId, language) => {
      const data = await this.#post(
        "/affiliate/createPayInvoicePaymentIntent",
        true,
        { invoiceId, language }
      );

      return data.clientSecret;
    },
    getInvoiceByClientSecret: async (clientSecret) => {
      const invoice = await this.#post(
        "/affiliate/getInvoiceByClientSecret",
        true,
        { clientSecret }
      );

      return invoice;
    },

    getGiftcardById: async (giftcardId) => {
      const giftcard = await this.#post("/affiliate/getGiftcardById", true, {
        giftcardId,
      });

      return giftcard;
    },
    updateGiftcard: async (giftcardId, reference, emails) => {
      const giftcard = await this.#post("/affiliate/updateGiftcard", true, {
        giftcardId,
        reference,
        emails,
      });

      return giftcard;
    },
    deleteGiftcard: async (giftcardId) => {
      await this.#post("/affiliate/deleteGiftcard", true, {
        giftcardId,
      });
    },
    createGiftcard: async (packageId, reference, emails) => {
      const giftcard = await this.#post("/affiliate/createGiftcard", true, {
        packageId,
        reference,
        emails,
      });

      return giftcard;
    },
    getRunningBalance: async () => {
      const data = await this.#post("/affiliate/getRunningBalance", true);

      return data.balance;
    },
    getDueBalance: async () => {
      const data = await this.#post("/affiliate/getDueBalance", true);

      return data.balance;
    },
    getChargesByMonth: async (month) => {
      const charges = await this.#post("/affiliate/getChargesByMonth", true, {
        month,
      });

      return charges;
    },
    getInvoices: async (year) => {
      const invoices = await this.#post("/affiliate/getInvoices", true, {
        year,
      });

      return invoices;
    },
    getInvoiceById: async (invoiceId) => {
      const invoice = await this.#post("/affiliate/getInvoiceById", true, {
        invoiceId,
      });

      return invoice;
    },

    updateLanguage: async (language) => {
      const affiliate = await this.#post("/affiliate/updateLanguage", true, {
        language,
      });

      return affiliate;
    },
  };
}

export default ApiServerAffiliateClient;
