const util = {
  canDelete: (media, album, user) => {
    return (
      !album.status.ended &&
      (media.ownerUserId.toString() === user._id.toString() ||
        album.ownerUserId.toString() === user._id.toString())
    );
  },
  canDownload: (
    media,
    album,
    deviceId,
    md5DbItems,
    mediaLibraryAssets,
    downloadQueue
  ) => {
    if (
      mediaLibraryAssets.some(
        (a) => deviceId === media.deviceId && a.id === media.asset.id
      )
    ) {
      return false;
    }

    if (
      md5DbItems.some(
        (i) =>
          (i.file.md5 === media.originalFile.md5 &&
            i.file.size === media.originalFile.size) ||
          (media.normalizedFile &&
            i.file.md5 === media.normalizedFile.md5 &&
            i.file.size === media.normalizedFile.size)
      )
    ) {
      return false;
    }

    if (downloadQueue.some((i) => i.media._id === media._id)) {
      return false;
    }

    return album.status.canViewAndDownloadMedias;
  },
  isDownloaded: (media, deviceId, mediaLibraryAssets, md5Items) => {
    if (
      mediaLibraryAssets.some(
        (a) => deviceId === media.deviceId && a.id === media.asset.id
      )
    ) {
      return true;
    }

    if (
      md5Items.some(
        (i) =>
          (i.file.md5 === media.originalFile.md5 &&
            i.file.size === media.originalFile.size) ||
          (media.normalizedFile &&
            i.file.md5 === media.normalizedFile.md5 &&
            i.file.size === media.normalizedFile.size)
      )
    ) {
      return true;
    }

    return false;
  },
  isVideo: (media) => {
    return media.asset.mediaType === "video";
  },
  isPhoto: (media) => {
    return media.asset.mediaType === "photo";
  },
  hasBeenProcessed: (media) => {
    return !!media.normalizedFile;
  },
};

export default util;
