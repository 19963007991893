import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

export default ({
  show,
  setShow,
  title,
  children,
  footer,
  size,
  closeButton = true,
  backdrop = true,
  keyboard = true,
  fullscreen = false,
  bodyRef,
}) => {
  const bodyRef2 = bodyRef || useRef();
  const scrollbtnRef = useRef();

  const updateScrollbarVisibility = () => {
    if (!scrollbtnRef.current) return;
    if (!bodyRef2.current) return;

    scrollbtnRef.current.style.visibility =
      bodyRef2.current.clientHeight < bodyRef2.current.scrollHeight
        ? "visible"
        : "collapse";
  };

  useEffect(() => {
    if (!bodyRef2.current) return;

    const observer = new ResizeObserver(updateScrollbarVisibility);
    observer.observe(bodyRef2.current);

    return () => observer.disconnect();
  });

  // useEffect(() => {
  //   // resize event ca only be set on window
  //   const onResize = () => {
  //     updateScrollbarVisibility();
  //   };

  //   window.addEventListener("resize", onResize);
  //   onResize();

  //   return () => window.removeEventListener("resize", onResize);
  // }, []);

  const handleScrollClicked = () => {
    if (!bodyRef2.current) return;

    bodyRef2.current.scroll({
      top: bodyRef2.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop={backdrop}
      keyboard={keyboard}
      fullscreen={fullscreen}
      size={size}
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={fullscreen ? "" : "modal-body-height"}
        style={{ backgroundColor: "#eee" }}
        ref={bodyRef2}
      >
        <div
          className="app"
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            border: "1px solid lightgray",
          }}
        >
          <div
            style={{ position: "absolute", top: 0, right: 0 }}
            ref={scrollbtnRef}
          >
            <button className="btn btn-modal m-1" onClick={handleScrollClicked}>
              <FontAwesomeIcon icon={faArrowsUpDown} />
            </button>
          </div>
          {children}
        </div>
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
