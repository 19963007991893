import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import albumUtil from "../../../../../../utils/album.util";

import { useLanguage } from "../../../../../language.context";
import Modal from "../../../../../controls/modal";

import { useAppState } from "../../../appState.context";

import WelcomeControl from "./welcome.control";

export default () => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();

  const bodyRef = useRef();
  const [isWorking, setIsWorking] = useState(false);
  const [show, setShow] = useState(false);

  // stack
  const [items, setItems] = useState([
    {
      titleLanguageStringId: languageCtx.getIdFromId("Welcome"),
      component: (ctx) => <WelcomeControl ctx={ctx} />,
    },
  ]);

  useEffect(() => {
    if (!appStateCtx.album.get()) return;
    if (!appStateCtx.user.get()) return;

    const show =
      albumUtil.isUserOwner(appStateCtx.album.get(), appStateCtx.user.get()) &&
      !appStateCtx.album.welcomeMessageShown;

    setShow(show);
  }, [appStateCtx.album.get()?._id, appStateCtx.album.welcomeMessageShown]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTo({ top: 0 });
    }
  }, [items]);

  const handleBackClicked = () => {
    if (isWorking) return;

    popItem();
  };

  const pushItem = (titleLanguageStringId, component, onBackClicked) => {
    setItems([...items, { titleLanguageStringId, component, onBackClicked }]);
  };

  const popItem = (count = 1) => {
    const items2 = [...items];
    while (count-- > 0) {
      // cannot remove rootItem
      if (items.length > 1) {
        if (items[items.length - 1].onBackClicked) {
          items[items.length - 1].onBackClicked();
        }
        items2.pop();
      }
    }
    setItems(items2);
  };

  const reset = () => {
    while (items.length > 1) {
      if (items[items.length - 1].onBackClicked) {
        items[items.length - 1].onBackClicked();
      }
      items.pop();
    }

    setItems([...items]);
  };

  const setShowProxy = (show) => {
    if (!show) {
      // appStateCtx.album.markWelcomedAsync(
      //   appStateCtx.user.get()._id,
      //   appStateCtx.album.get()._id
      // );
      setTimeout(() => reset(), 500);
    }

    // TODO reset
    setShow(show);
  };

  if (items.length <= 0) return;

  const currentItem = items[items.length - 1];

  const ctx = {
    // stack
    hasParent: items.length > 1,
    currentItem,
    pushItem,
    popItem,
    items,

    isWorking,
    setIsWorking,

    // modal
    show,
    setShow: setShowProxy,
  };

  return (
    <>
      <Modal
        show={show}
        setShow={setShowProxy}
        title={
          <>
            {items.length > 1 ? (
              <span onClick={handleBackClicked} className="clickable">
                <FontAwesomeIcon
                  style={{
                    margin: -10,
                    margin: -10,
                    padding: 10,
                    padding: 10,
                  }}
                  icon={faCaretLeft}
                />
                <span className="ms-2">
                  {languageCtx.getStringFromId(
                    currentItem.titleLanguageStringId
                  )}
                </span>
              </span>
            ) : (
              <span>
                {languageCtx.getStringFromId(currentItem.titleLanguageStringId)}
              </span>
            )}
          </>
        }
        size="lg"
        closeButton={false}
        backdrop={"static"}
        keyboard={false}
      >
        {currentItem.component(ctx)}
      </Modal>
    </>
  );
};
