const util = {
  isCustomError: (ex) => {
    if (ex?.error?.id) return true;
    return false;
  },

  isError: (ex) => {
    if (ex instanceof Error) return true;
    return false;
  },
};

export default util;
