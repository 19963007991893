import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import colors from "../../../../utils/colors";

import { useLanguage } from "../../../language.context";
import { useException } from "../../../exception.context";

import { useAppState } from "../appState.context";

import { useAuth } from "./context";

export default () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const authCtx = useAuth();

  const inputRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    if (!authCtx.email) {
      navigate("../enteremail");
      return;
    }

    authCtx.setTermsOfService(languageCtx.getTermsOfService());
    authCtx.setPrivacyPolicy(languageCtx.getPrivacyPolicy());
    inputRef.current.focus();
  }, []);

  const handleNextClicked = async () => {
    if (!isFormOk()) return;

    try {
      await appStateCtx.apiServerClient.user.sendSignupVerificationCode(
        authCtx.email,
        languageCtx.language.code
      );
      navigate("../enterverificationcode");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!authCtx.name) return false;
    if (authCtx.name.length < 3) return false;
    if (!authCtx.termsOfService) return false;
    if (!authCtx.privacyPolicy) return false;

    return true;
  };

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container text-center">
        <div className="fs-2">
          {languageCtx.getStringFromId(
            "We see that you are a new user, what username would you like?"
          )}
        </div>

        <div className="container">
          <input
            ref={inputRef}
            className="form-control mt-4 text-center form-control-lg"
            value={authCtx.name}
            onChange={(e) => authCtx.setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                btnRef.current.click();
              }
            }}
          />
        </div>

        <div className="mt-4">
          <div className="text-center p-0 m-0">
            {languageCtx.getStringFromId("bySigningUpYouAcceptOur")}{" "}
            <a
              href={languageCtx.getPrivacyPolicy().url}
              target="_blank"
              referrerPolicy="no-referrer"
              style={{ color: colors.darkblue }}
            >
              {languageCtx.getStringFromId("privacyPolicy")}
            </a>{" "}
            {languageCtx.getStringFromId("and our")}{" "}
            <a
              href={languageCtx.getTermsOfService().url}
              target="_blank"
              referrerPolicy="no-referrer"
              style={{ color: colors.darkblue }}
            >
              {languageCtx.getStringFromId("termsOfService")}
            </a>{" "}
          </div>
        </div>

        <div className="mt-4">
          <button
            ref={btnRef}
            disabled={!isFormOk()}
            className="btn btn-lg btn-black"
            onClick={handleNextClicked}
          >
            {languageCtx.getStringFromId("Next")}
          </button>
        </div>
      </div>
    </div>
  );
};
