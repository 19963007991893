import { useEffect, useState } from "react";

import deviceUtil from "../../../../../../../utils/device.util";

import { useLanguage } from "../../../../../../language.context";

import { useAppState } from "../../../../appState.context";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [languages, setLanguages] = useState([]);
  const [languageCode, setLanguageCode] = useState();

  const refresh = async () => {
    try {
      const languages = await appStateCtx.apiServerClient.language.get();

      setLanguages(languages);
      setLanguageCode(languageCtx.language.code);
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  let points;
  if (deviceUtil.isIOS) {
    points = [
      {
        html: languageCtx.getStringFromId("iOS_AddToHomeScreen1"),
      },
      {
        html: languageCtx.getStringFromId("iOS_AddToHomeScreen2"),
      },
      {
        html: languageCtx.getStringFromId("iOS_AddToHomeScreen3"),
      },
    ];
  } else if (deviceUtil.isAndroid) {
    points = [
      {
        html: languageCtx.getStringFromId("android_AddToHomeScreen1"),
      },
      {
        html: languageCtx.getStringFromId("android_AddToHomeScreen2"),
      },
    ];
  } else {
    points = [
      {
        html: languageCtx.getStringFromId("general_AddToHomeScreen1"),
      },
    ];
  }

  if (!languageCode) return null;

  return (
    <div className="p-4">
      <div className="fs-2">
        {languageCtx.getStringFromId("Add Fotisima to home screen")}
      </div>
      <div className="mt-3">
        {languageCtx.getStringFromId(
          "Follow this procedure to add Fotisima to your home screen"
        )}
      </div>
      <div className="mt-3">
        <ol>
          {points.map((p, index) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{
                __html: p.html,
              }}
            ></li>
          ))}
        </ol>
      </div>
    </div>
  );
};
