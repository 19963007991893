const util = {
  isVideo: (asset) => {
    // .duration > 0
    return asset?.mediaType === "video";
  },
  isPhoto: (asset) => {
    return asset?.mediaType === "photo";
  },
  duration: (asset) => asset?.duration,

  durationToString: (duration) => {
    duration = Math.round(duration);
    const seconds = duration % 60;
    const minutes = Math.floor(duration / 60);
    const str = `${minutes}:${seconds.toString().padStart(2, "0")}`;
    return str;
  },
  isUploaded: (asset, medias, items) => {
    if (medias.some((m) => m.asset.id === asset.id)) return true;

    const item = items.find((i) => i.asset.id === asset.id);
    if (item) {
      if (
        medias.some(
          (m) =>
            (m.originalFile.md5 === item.file.md5 &&
              m.originalFile.size === item.file.size) ||
            (m.normalizedFile &&
              m.normalizedFile.md5 === item.file.md5 &&
              m.normalizedFile.size === item.file.size)
        )
      ) {
        return true;
      }
    }

    return false;
  },
};

export default util;
