import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLanguage } from "../language.context";

export default ({ items }) => {
  const languageCtx = useLanguage();

  const items2 = items.filter((i) => !i.visible || i.visible());

  return (
    <div className="row m-0 p-2">
      {items2.map((d, index) => (
        <div className="col-6 p-2" key={index}>
          <div
            className="text-center p-3 clickable settings-menu-item2 border rounded h-100"
            onClick={d.onClick}
          >
            <div>{d.icon && <FontAwesomeIcon icon={d.icon} size="2x" />}</div>
            <div className="mt-1 fw-bold">
              {languageCtx.getStringFromId(d.titleLanguageStringId)}
            </div>
            {d.subtitleLanguageStringId && (
              <div className="mt-1" style={{ fontSize: 12 }}>
                {languageCtx.getStringFromId(d.subtitleLanguageStringId)}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
