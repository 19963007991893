import { Routes, Route, Navigate } from "react-router-dom";

import { AuthContextProvider } from "./context";
import EnterNamePage from "./entername.control";
import EnterEmailPage from "./enteremail.control";
import EnterVerificationCodePage from "./enterverificationcode.control";

const Redirect = () => {
  return <Navigate to="/app/enteremail" replace={true} />;
};

export default () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="enteremail" element={<EnterEmailPage />} />
        <Route path="entername" element={<EnterNamePage />} />
        <Route
          path="enterverificationcode"
          element={<EnterVerificationCodePage />}
        />
        <Route path="*" element={<Redirect />} />
      </Routes>
    </AuthContextProvider>
  );
};
