import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-power-tooltip";

import colors from "../../../../../../utils/colors";

import { useLanguage } from "../../../../../language.context";

import { useAppState } from "../../../appState.context";

import UploadModal from "./upload.modal";
import {
  UploadQueueContextProvider,
  useUploadQueue,
} from "./uploadQueue.context";

const PlusButton = ({ style }) => {
  const languageCtx = useLanguage();
  const uploadQueueCtx = useUploadQueue();

  const [showTip, setShowTip] = useState(false);

  const handleFilesChanged2 = async (e) => {
    const files = Array.from(e.target.files);

    uploadQueueCtx.add(files);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTip(true);
      setTimeout(() => {
        setShowTip(false);
      }, 5 * 1000);
    }, 5 * 1000);
  }, []);

  return (
    <div className="btn-plus-ctn">
      {showTip && (
        <Tooltip
          show={showTip}
          position="top right"
          moveRight="-32px"
          moveUp="6px"
          arrowAlign="end"
          animation="bounce"
          textBoxWidth="240px"
          static
          backgroundColor="#ffce3b"
          color="#000000"
          flat
        >
          <div className="text-center">
            {languageCtx.getStringFromId(
              "Upload your photos by pressing here!"
            )}
          </div>
        </Tooltip>
      )}
      <label
        className="btn btn-blue d-flex justify-content-center align-items-center me-3 mb-3"
        style={Object.assign(
          { borderRadius: 200, width: 50, height: 50 },
          style
        )}
        htmlFor="upload"
        id="plus"
      >
        <FontAwesomeIcon icon={faPlus} color={colors.white} />
        <input
          type="file"
          id="upload"
          multiple
          style={{ display: "none" }}
          accept=".png,.jpeg,.jpg,.mp4,.mov"
          onChange={handleFilesChanged2}
          onClick={(e) => (e.target.value = "")}
        />
      </label>
    </div>
  );
};
// const Typer = ({ speed = 250, text = " Introduce your text" }) => {
//   const [idx, setidx] = useState(0);
//   useEffect(() => {
//     const timer = window.setInterval(() => setidx((v) => v + 1), speed);
//     return () => window.clearInterval(timer);
//   });

//   return <div>{text.substring(0, idx)}</div>;
// };

// <Typer text="Click here and upload your photos" speed={50} />

export default () => {
  const appStateCtx = useAppState();

  if (
    !appStateCtx.album.get().status.started ||
    appStateCtx.album.get().status.ended
  )
    return null;

  return (
    <UploadQueueContextProvider>
      <PlusButton />
      <UploadModal />
    </UploadQueueContextProvider>
  );
};
