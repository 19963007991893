import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import stringUtil from "../../../../../../utils/string.util";
import colors from "../../../../../../utils/colors";
import errorUtil from "../../../../../../utils/error.util";

import { useLanguage } from "../../../../../language.context";
import Modal from "../../../../../controls/modal";

import { UPLOADSTATES, useUploadQueue } from "./uploadQueue.context";

export default () => {
  const languageCtx = useLanguage();
  const uploadQueueCtx = useUploadQueue();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (uploadQueueCtx.items.length > 0 && !show) setShow(true);
  }, [uploadQueueCtx.items]);

  const getUploadStatus = (item) => {
    if (item.state === UPLOADSTATES.PENDING) {
      return <div>{languageCtx.getStringFromId("Pending")}</div>;
    }

    if (item.error?.ex) {
      return (
        <div style={{ color: colors.red }}>
          {languageCtx.getStringFromId("Unknown error, try again later")}
        </div>
      );
    }

    if (item.error?.stringId) {
      return (
        <div style={{ color: "red" }}>
          {languageCtx.getStringFromId(item.error.stringId)}
        </div>
      );
    }

    if (item.error?.id) {
      return (
        <div style={{ color: colors.red }}>
          {languageCtx.getErrorFromId(item.error.id)}
        </div>
      );
    }

    if (item.state === UPLOADSTATES.DONE) {
      return <div>{languageCtx.getStringFromId("Done!")}</div>;
    }

    return (
      <div>
        {stringUtil.formatString(
          languageCtx.getStringFromId("UploadingX"),
          ` ${Math.round(item.progress)}%`
        )}
      </div>
    );
  };

  const currentItem = uploadQueueCtx.items.find(
    (i) => i.state === UPLOADSTATES.UPLOADING
  );

  const missingItems = uploadQueueCtx.items.filter(
    (i) => i.state !== UPLOADSTATES.DONE
  );
  const errorItems = uploadQueueCtx.items.filter((i) => !!i.error);
  const successItems = uploadQueueCtx.items.filter((i) => !i.error);

  const handleCloseClicked = () => {
    setShow(false);
  };

  const getTitle = () => {
    if (missingItems.length > 0) {
      return (
        <>
          {`${languageCtx.getStringFromId("Uploading photos")} 
    ${
      uploadQueueCtx.items.length -
      uploadQueueCtx.items.filter((i) => i.state !== UPLOADSTATES.DONE).length +
      1
    }
    /${uploadQueueCtx.items.length}`}
        </>
      );
    } else if (missingItems.length === 0) {
      return <>{languageCtx.getStringFromId("Upload summary")}</>;
    } else {
      return "";
    }
  };

  const getError = (item) => {
    if (errorUtil.isCustomError(item)) {
      let error = languageCtx.getErrorFromId(item.error.id);

      if (item.error.params) {
        error = stringUtil.formatString(error, ...item.error.params);
      }

      return error;
    } else {
      return item.toString();
    }
  };

  const getBody = () => {
    if (missingItems.length > 0) {
      return (
        <div className="p-3">
          {currentItem && (
            <div>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {currentItem.file.name}
              </div>
              <div
                style={{
                  fontSize: 10,
                }}
              >
                {getUploadStatus(currentItem)}
              </div>
              <div
                style={{
                  marginTop: 10,
                  textAlign: "center",
                  backgroundColor: colors.darkblue,
                  color: colors.white,
                  borderRadius: 5,
                  padding: 5,
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                {languageCtx.getStringFromId("Uploading: Don't close window")}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      const errorDescriptions = errorItems
        .map((i) => getError(i))
        .reduce((a, b) => {
          if (!a.includes(b)) {
            a.push(b);
          }

          return a;
        }, []);

      return (
        <div className="p-3 text-center">
          <div className="fs-2 mt-3">
            {languageCtx.getStringFromId("Thank you for uploading")}
          </div>
          <div className="mt-3">
            <table className="table d-inline">
              <tbody>
                <tr>
                  <td className="text-start text-success">
                    {languageCtx.getStringFromId("Successfull uploads")}
                  </td>
                  <td className="text-end text-success">
                    {successItems.length}
                  </td>
                </tr>
                {errorItems.length > 0 && (
                  <tr>
                    <td className="text-start text-danger">
                      {languageCtx.getStringFromId("Failed uploads")}
                    </td>
                    <td className="text-end text-danger">
                      {errorItems.length}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <button className="btn btn-blue" onClick={handleCloseClicked}>
              {languageCtx.getStringFromId("close")}
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      show={show}
      setShow={setShow}
      keyboard={false}
      backdrop="static"
      closeButton={false}
      title={getTitle()}
    >
      {getBody()}
    </Modal>
  );
};
