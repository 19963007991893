const insertScrollbarWidth = () => {
  let el = document.createElement("div");
  el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
  document.body.appendChild(el);
  const scrollbarWidth = el.offsetWidth - el.clientWidth;
  el.remove();
  document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
};

const insert1vh = () => {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.body.style.setProperty("--vh", `${vh}px`);
};

const util = {
  getScrollbarWidth: () =>
    parseInt(
      document.body.style
        .getPropertyValue("--scrollbar-width")
        .replace("px", "")
    ),
  insertVariablesIntoCss: () => {
    insertScrollbarWidth();
    insert1vh();
  },
};

export default util;
