import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import errorUtil from "../../../../../../utils/error.util";

import { useException } from "../../../../../exception.context";
import Modal from "../../../../../controls/modal";

import { useAppState } from "../../../appState.context";

import JoinControl from "./join.control";

export default () => {
  const params = useParams();
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [show, setShow] = useState(false);
  const [album, setAlbum] = useState();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        // console.log(params);
        // const matches = new RegExp(/\/app\/albums\/(\w+)(\/join)+/).exec(
        //   window.location.pathname
        // );

        // const albumId = matches.length > 1 ? matches[1] : undefined;

        if (!params.albumId) {
          return;
        }

        if (!appStateCtx.albums.get().find((a) => a._id === params.albumId)) {
          const album = await appStateCtx.apiServerClient.album.getById(
            params.albumId
          );
          setShow(true);
          setAlbum(album);
        } else {
          navigate("..");
        }
      } catch (ex) {
        if (errorUtil.isCustomError(ex)) {
          if (
            ex.error.id === "SERVER_ALBUM_NOT_FOUND" ||
            ex.error.id === "SERVER_ALBUM_INVITES_FULL"
          ) {
            setShow(true);
          }
        } else {
          exceptionCtx.handleException(ex);
        }
      }
    };

    refresh();
  }, []);

  const setShowProxy = (show) => {
    // TODO reset
    setShow(show);
  };

  const ctx = {
    album,
    setAlbum,

    isWorking,
    setIsWorking,

    // modal
    show,
    setShow: setShowProxy,
  };

  return (
    <Modal
      show={show}
      setShow={setShowProxy}
      title={
        <img src="/assets/images/fotisima-v2.svg" style={{ height: "3rem" }} />
      }
      size="lg"
      closeButton={false}
      backdrop={"static"}
      keyboard={false}
    >
      <JoinControl ctx={ctx} />
    </Modal>
  );
};
