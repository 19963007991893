const colors = {
  lightblue: "#6ac3e1",
  lightlightblue: "#f0f6f8",
  blue: "#1FB0E1",
  darkblue: "#141B41",
  // pink: "#FF3366",
  // limegreen: "#C9D5B5",
  white: "#FFFFFF",
  //test: "#141B41",
  black: "#000000",
  bs_gray: "#DEE2E6",
  lightgray: "#fbfbfd",
  gray: "gray",
  transparent: "transparent",
  red: "red",
};

export default colors;
