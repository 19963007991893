import { useState } from "react";

import validateUtil from "../../../../../utils/validate.util";

import { useLanguage } from "../../../../language.context";
import TextInput from "../../../../controls/text.input";
import Modal from "../../../../controls/modal";
import { useException } from "../../../../exception.context";

import { useAffiliateAppState } from "../../appState.context";
import { useMessage } from "../../../../message.context";

export default () => {
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const appStateCtx = useAffiliateAppState();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const handleChangePasswordClicked = () => {
    setShowChangePasswordModal(true);
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(password, true, 6)) return false;
    if (password !== password2) return false;

    return true;
  };

  const handleChangePasswordConfirmClicked = async () => {
    try {
      await appStateCtx.apiServerClient.affiliate.updatePassword(password);
      messageCtx.showSuccess(languageCtx.getStringFromId("Password updated"));
      setPassword("");
      setPassword2("");
      setShowChangePasswordModal(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>{languageCtx.getStringFromId("Affiliate")}</h3>
        </div>
        <div className="mt-3">
          <label className="form-label fw-bold">
            {languageCtx.getStringFromId("name")}
          </label>
          <TextInput
            readonly={true}
            value={appStateCtx.affiliate.get().fullname}
          />
        </div>
        <div className="mt-2">
          <label className="form-label fw-bold">
            {languageCtx.getStringFromId("Email")}
          </label>
          <TextInput
            readonly={true}
            value={appStateCtx.affiliate.get().email}
          />
        </div>
        <div className="mt-2">
          <label className="form-label fw-bold">
            {languageCtx.getStringFromId("Phone")}
          </label>
          <TextInput
            readonly={true}
            value={appStateCtx.affiliate.get().phone}
          />
        </div>

        <div className="mt-2">
          <button
            className="btn btn-blue"
            onClick={handleChangePasswordClicked}
          >
            {languageCtx.getStringFromId("Change password")}
          </button>
        </div>
      </div>
      <Modal
        title={languageCtx.getStringFromId("Change password")}
        show={showChangePasswordModal}
        setShow={setShowChangePasswordModal}
      >
        <div className="p-4 text-center">
          <div>
            <label className="form-label fw-bold">
              {languageCtx.getStringFromId("Enter new password")}
            </label>
            <TextInput
              type="password"
              value={password}
              onChange={setPassword}
            />
          </div>
          <div className="mt-2">
            <label className="form-label fw-bold">
              {languageCtx.getStringFromId("Reenter new password")}
            </label>
            <TextInput
              type="password"
              value={password2}
              onChange={setPassword2}
            />
          </div>

          <div className="mt-3">
            <button
              className="btn btn-gray"
              onClick={() => setShowChangePasswordModal(false)}
            >
              {languageCtx.getStringFromId("Cancel")}
            </button>
            <button
              className="btn btn-blue ms-1"
              disabled={!isFormOk()}
              onClick={handleChangePasswordConfirmClicked}
            >
              {languageCtx.getStringFromId("update")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
