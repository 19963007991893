const util = {
  encodeComponent: (component) => {
    //const s = encodeURIComponent(component); // version 2022-12-01
    const s = encodeURIComponent(encodeURIComponent(component)); // version 2023-01-18
    return s;
  },
  isAppAlbum: () => /^\/app\/albums\/[^\/\s]+$/.test(window.location.pathname),
  isAppAlbumJoin: () =>
    /^\/app\/albums\/[^\/\s]+\/join$/.test(window.location.pathname),
  isAppAlbumPresentation: () =>
    /^\/app\/albums\/[^\/\s]+\/presentation$/.test(window.location.pathname),
  isAppPrivacyPolicy: () =>
    /^\/app\/privacypolicy$/.test(window.location.pathname),
  isAppPayment: () => /^\/app\/payment$/.test(window.location.pathname),
  isAppTermsOfService: () =>
    /^\/app\/termsofservice$/.test(window.location.pathname),
  isAppJoinDemo: () => /^\/app\/joindemo$/.test(window.location.pathname),
  isAppAlbums: () => /^\/app\/albums$/.test(window.location.pathname),
  isAppAlbum: () => /^\/app\/albums\/[^\/\s]+$/.test(window.location.pathname),
};

export default util;
