import React, { createContext, useContext, useRef } from "react";

import stringUtil from "../utils/string.util";
import errorUtil from "../utils/error.util";
import errorbaseUtil from "../utils/errorbase.util";

import { useMessage } from "./message.context";
import { useLanguage } from "./language.context";

const ErrorContext = createContext(null);

export const useException = () => {
  return useContext(ErrorContext);
};

export const messageStates = {
  success: 1,
  error: 2,
  info: 3,
  warning: 4,
};

// const isServerError = (ex) => {
//   if (!ex?.error) return false;
//   return true;
// };

export const ExceptionContextProvider = ({ children }) => {
  const messageCtx = useMessage();
  const languageCtx = useLanguage();

  const handlersRef = useRef([]);

  const handleException = (ex) => {
    errorbaseUtil.insertException(ex);

    for (var handler of handlersRef.current) {
      handler(ex);
    }

    if (errorUtil.isCustomError(ex)) {
      // if (ex.error.id === "SERVER_USER_NOT_AUTHENTICATED") {
      //   // force goto login page
      //   appStateCtx.user.signout();
      // }

      let error = languageCtx.getErrorFromId(ex.error.id);

      if (ex.error.params) {
        error = stringUtil.formatString(error, ...ex.error.params);
      }

      messageCtx.showError(error);
    } else {
      messageCtx.showError(ex.toString());
    }
  };

  const addHandler = (handler) => {
    handlersRef.current.push(handler);
  };

  const removeHandler = (handler) => {
    handlersRef.current = handlersRef.current.filter((l) => l !== handler);
  };

  const initialValue = {
    handleException,
    addHandler,
    removeHandler,
  };

  return (
    <ErrorContext.Provider value={initialValue}>
      {children}
    </ErrorContext.Provider>
  );
};
