import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

import albumUtil from "../../../../utils/album.util";

import { useLanguage } from "../../../language.context";
import Modal from "../../../controls/modal";

export default () => {
  const languageCtx = useLanguage();

  const demoAlbumId = "65dce674649b064adebd1cbc";

  return (
    <Modal
      show={true}
      setShow={() => {}}
      title={
        <img src="/assets/images/fotisima-v2.svg" style={{ height: "3rem" }} />
      }
      size="lg"
      closeButton={false}
      backdrop={"static"}
      keyboard={false}
    >
      <div className="p-4">
        <h3>{languageCtx.getStringFromId("Try our demo")}</h3>
        <div>
          {languageCtx.getStringFromId(
            "Fotisima is a web app that is used in events..."
          )}
        </div>

        <div className="mt-3 text-center">
          <QRCode
            value={albumUtil.getInviteLink({ _id: demoAlbumId })}
            size={160}
          />
        </div>

        <div
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: languageCtx.getStringFromId(
              "Scan the code above or simply click continue..."
            ),
          }}
        ></div>

        <div className="mt-4 text-center">
          <Link className="btn btn-gray" to="/">
            {languageCtx.getStringFromId("Cancel")}
          </Link>
          <Link
            className="btn btn-blue ms-1"
            to={`/app/albums/${demoAlbumId}/join`}
          >
            {languageCtx.getStringFromId("continue")}
          </Link>
        </div>
      </div>
    </Modal>
  );
};
