import { useEffect, useRef, useState } from "react";

import assetUtil from "../../../../../utils/asset.util";

import { useLanguage } from "../../../../language.context";

import { useAppState } from "../../appState.context";
import { useNavigate } from "react-router-dom";

const SLIDE_SECONDS = 20;

export default () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const mediaRef = useRef();
  const [redrawCounter, setRedrawCounter] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const showNextRef = useRef(0);

  const medias = appStateCtx.medias.get();
  // remove videos with length greater than 30 seconds
  const medias2 = medias.filter(
    (m) =>
      m.reducedFile &&
      (assetUtil.isPhoto(m.asset) ||
        (assetUtil.isVideo(m.asset) && assetUtil.duration(m.asset) <= 30))
  );

  const showNext = () => {
    if (medias2.length === 0) {
      mediaRef.current = undefined;
      setRedrawCounter((c) => c + 1);
    } else if (medias2.length === 1) {
      mediaRef.current = medias2[0];
      setRedrawCounter((c) => c + 1);
    } else {
      const getNextMedia = () => {
        while (true) {
          const index = Math.floor(Math.random() * medias2.length);

          const media2 = medias2[index];

          if (mediaRef.current?._id !== media2?._id) return media2;
        }
      };

      const media2 = getNextMedia();

      mediaRef.current = media2;
      setRedrawCounter((c) => c + 1);

      if (assetUtil.isPhoto(media2.asset)) {
        clearTimeout(showNextRef.current);
        showNextRef.current = setTimeout(showNext, SLIDE_SECONDS * 1000);
      } else if (assetUtil.isVideo(media2.asset)) {
        // will goto next upon video finished
      }
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      if (mediaRef.current) {
        if (assetUtil.isPhoto(mediaRef.current.asset)) {
          showNextRef.current = setTimeout(showNext, SLIDE_SECONDS * 1000);
        } else if (assetUtil.isVideo(mediaRef.current.asset)) {
          // will goto next upon video finished
        }
      } else {
        showNext();
      }
      //showNextImageIdRef.current = setTimeout(showNext, SLIDE_SECONDS * 1000);
    }

    return () => {
      clearTimeout(showNextRef.current);
    };
  }, [medias]);

  useEffect(() => {
    const onFullscreenChanged = ({ matches }) => {
      setIsFullscreen(!!matches);
      if (!matches) {
        mediaRef.current = undefined;
        clearTimeout(showNextRef.current);
      }
    };

    const mediaMatch = window.matchMedia("(display-mode: fullscreen)");
    mediaMatch.addEventListener("change", onFullscreenChanged);

    return () => {
      mediaRef.current = undefined;
      clearTimeout(showNextRef.current);
      mediaMatch.removeEventListener("change", onFullscreenChanged);
    };
  }, []);

  const handleStartClicked = () => {
    showNext();
    setIsFullscreen(true);
    document.getElementById("fullscreen").requestFullscreen();
  };

  const handleExitClicked = () => {
    navigate("./..");
  };

  const handleVideoEnded = () => {
    showNext();
  };

  return (
    <div
      className="position-absolute top-0 bottom-0 left-0 right-0 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "black" }}
    >
      {!isFullscreen && (
        <>
          <div>
            <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
          </div>
          <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId("Welcome to Fotisima presentation")}
          </div>
          <div className="fs-3 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId(
              "Photos from your album will be randomly chosen and change every 20 seconds"
            )}
            <br />
            {languageCtx.getStringFromId(
              "Connect your device to a projector or a big screen and enjoy the show"
            )}
          </div>
          <div className="mt-5 btn-toolbar">
            <button
              className="btn btn-blue btn-lg"
              onClick={handleStartClicked}
            >
              {languageCtx.getStringFromId("Start")}
            </button>
            <button className="btn btn-gray btn-lg" onClick={handleExitClicked}>
              {languageCtx.getStringFromId("Exit")}
            </button>
          </div>
        </>
      )}
      <div
        id="fullscreen"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        {isFullscreen && !mediaRef.current && (
          <>
            <div>
              <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
            </div>
            <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
              {languageCtx.getStringFromId(
                "Upload photos to the shared album using Fotisima"
              )}
              <br />
              {languageCtx.getStringFromId("and see them here!")}
            </div>
          </>
        )}
        {isFullscreen && mediaRef.current && (
          <>
            {assetUtil.isPhoto(mediaRef.current.asset) && (
              <img
                src={mediaRef.current?.reducedFile?.uri}
                alt=""
                className="w-100 h-100 object-fit-contain"
              />
            )}
            {assetUtil.isVideo(mediaRef.current.asset) && (
              <video
                autoPlay
                muted
                playsInline
                src={mediaRef.current?.reducedFile?.uri}
                alt=""
                onEnded={handleVideoEnded}
                className="w-100 h-100 object-fit-contain"
              />
            )}
            <div className="position-absolute bottom-0 left-0 p-3">
              <img
                src="/assets/fulllogo5.png"
                style={{ height: 50, display: "inline" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
