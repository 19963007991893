import { useAppState } from "./appState.context";
import AuthNav from "./nouser/nav";
import AppNav from "./user/nav";
import urlUtil from "../../../utils/url.util";

export const isUrlAllowedWithoutUser = () => {
  const isUrlAllowed =
    urlUtil.isAppPrivacyPolicy() ||
    urlUtil.isAppTermsOfService() ||
    urlUtil.isAppPayment() ||
    urlUtil.isAppJoinDemo() ||
    urlUtil.isAppAlbumJoin() ||
    urlUtil.isAppAlbumPresentation() ||
    // old join link (remove following 2 on jan 2025)
    urlUtil.isAppAlbums() ||
    urlUtil.isAppAlbum();
  return isUrlAllowed;
};

export default () => {
  const appStateCtx = useAppState();

  return appStateCtx.user.get() || isUrlAllowedWithoutUser() ? (
    <AppNav />
  ) : (
    <AuthNav />
  );
};
