import { Routes, Route, Navigate } from "react-router-dom";

import GiftcardsPage from "./giftcards/giftcards.page";
import GiftcardPage from "./giftcards/giftcard.page";
import PaymentPage from "./payment.page";
import ChargesPage from "./charges/charges.page";
import WelcomePage from "./welcome/welcome.page";
import InvoicesPage from "./invoices/invoices.page";
import InvoicePage from "./invoices/invoice.page";
import ProfilePage from "./profile/profile.page";

export default () => {
  return (
    <Routes>
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/giftcards">
        <Route index element={<GiftcardsPage />} />
        <Route path="payment" element={<PaymentPage />} />
        <Route path="invoices">
          <Route index element={<InvoicesPage />} />
          <Route path=":invoiceId" element={<InvoicePage />} />
        </Route>
        <Route path="charges" element={<ChargesPage />} />
        <Route path="new" element={<GiftcardPage />} />
        <Route path=":giftcardId" element={<GiftcardPage />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/affiliate/welcome" replace={true} />}
      />
    </Routes>
  );
};
