import { Duration } from "luxon";
import stringUtil from "./string.util";

const util = {
  time: {
    secondsToMinutes: (seconds) => {
      return Duration.fromMillis(seconds * 1000).as("minutes");
    },
  },
  price: {
    fromMillis: (millis) => {
      return millis / 1000000;
    },
  },
  getInviteLink: (album, languageCode) => {
    return `${window.location.protocol}//${window.location.host}/app/albums/${album._id}/join`;
  },
  getInviteCardsLink: (album, languageCode) => {
    return `${window.location.protocol}//${window.location.host}/invitecards/${
      album._id
    }${languageCode ? "?la=" + languageCode : ""}`;
  },
  getStateColor: (album) => {
    if (!album.status.started) {
      return "orange";
    } else if (!album.status.ended) {
      return "green";
    } else {
      return "red";
    }
  },

  hasBiggestPackage: (album) => {
    return album.package.productId === "l21";
  },
  isMediasAlmostFull: (album) => {
    return (
      ((album.usedPhotoCount + album.usedVideoCount) /
        album.package.mediaCount) *
        100 >
      90
    );
  },
  isAlbum: (album) => {
    return !!album;
  },
  isUsersAlmostFull: (album) => {
    return (album.users.length / album.package.inviteCount) * 100 > 90;
  },
  isUsersFull: (album) => {
    if (!album) return false;
    return album.users.length >= album.package.inviteCount;
  },
  isUserOwner: (album, user) => {
    if (!album) return false;
    if (!user) return false;
    return album.ownerUserId === user._id;
  },
  hasEnded: (album) => {
    if (!album) return false;
    return album.status.ended;
  },
  hasStarted: (album) => {
    if (!album) return false;
    return album.status.started;
  },
  isArchived: (album) => {
    if (!album) return false;
    return album.status.archived;
  },
  isMediaDeleted: (album) => {
    if (!album) return false;
    return album.status.mediasDeleted;
  },
  getDiffAsString: (languageCtx, diff) => {
    if (diff.days > 0) {
      return stringUtil.formatString(
        languageCtx.getStringFromId("X days"),
        diff.days
      );
    }
    if (diff.hours > 1) {
      return stringUtil.formatString(
        languageCtx.getStringFromId("X hours"),
        Math.floor(diff.hours)
      );
    } else {
      return languageCtx.getStringFromId("Soon");
    }
  },
};

export default util;
