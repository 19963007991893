import { Routes, Route, Navigate } from "react-router-dom";

import { useAppState } from "../appState.context";

import NoAlbumsPage from "./noalbums/noalbums.page";
import AlbumPage from "./albums/album.page";
import JoinPage from "./albums/join.page";
import PresentationScreen from "./albums/presentation.page";
import PrivacyPolicyScreen from "./privacypolicy.page";
import TermsOfServiceScreen from "./termsofservice.page";
import PaymentPage from "./payment2.page";
import JoinDemoScreen from "./joindemo.page";

const Redirect = () => {
  const appStateCtx = useAppState();

  if (appStateCtx.album.get()) {
    return (
      <Navigate
        to={`/app/albums/${appStateCtx.album.get()._id}`}
        replace={true}
      />
    );
  } else {
    return <Navigate to="/app/albums" replace={true} />;
  }
};

export default () => {
  return (
    <Routes>
      <Route path="albums">
        <Route index element={<NoAlbumsPage />} />
        <Route path=":albumId">
          <Route index element={<AlbumPage />} />
          <Route path="join" element={<JoinPage />} />
          <Route path="presentation" element={<PresentationScreen />} />
        </Route>
      </Route>
      {/* this should be deleted */}
      <Route path="joindemo" element={<JoinDemoScreen />} />
      <Route path="termsofservice" element={<TermsOfServiceScreen />} />
      <Route path="privacypolicy" element={<PrivacyPolicyScreen />} />
      {/* <Route path="invitecards/:albumId" element={<InviteCardsScreen />} /> */}
      <Route path="payment" element={<PaymentPage />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};
