import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import albumUtil from "../../utils/album.util";
import ApiServerClient from "../../utils/apiserver.user.client";
import stringUtil from "../../utils/string.util";
import settings from "../../utils/settings";

import CheckButtons from "../controls/checkbuttons.control";
import HeaderControl from "../controls/header2.control";
import FooterControl from "../controls/footer.control";
import Modal from "../controls/modal";
import { useLanguage } from "../language.context";
import { useException } from "../exception.context";

import HelpControl from "./app/user/albums/menu.model/app/help.control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const apiServerClient = new ApiServerClient();

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [packages, setPackages] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const refresh = async () => {
    try {
      const store = await apiServerClient.package.getStoreByIPLocation();

      const packages = await apiServerClient.package.getAllByCurrencyCode(
        store.currencyCode
      );

      packages.sort((a, b) => a.priceAmountMicros - b.priceAmountMicros);

      setPackages(packages);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const howDoesItWorkTopics = [
    {
      title: languageCtx.getStringFromId("How does it work title 1"),
      description: languageCtx.getStringFromId(
        "How does it work description 1"
      ),
    },
    {
      title: languageCtx.getStringFromId("How does it work title 2"),
      description: languageCtx.getStringFromId(
        "How does it work description 2"
      ),
    },
    {
      title: languageCtx.getStringFromId("How does it work title 3"),
      description: languageCtx.getStringFromId(
        "How does it work description 3"
      ),
    },
    {
      title: languageCtx.getStringFromId("How does it work title 4"),
      description: languageCtx.getStringFromId(
        "How does it work description 4"
      ),
    },
  ];

  const handleCompareClicked = (e) => {
    e.preventDefault();
    setShowCompareModal(true);
  };

  const handleScroll = (anchor) => {
    const element = document.querySelector(anchor);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (packages.length === 0) return null;

  const _package = packages[selectedIndex];

  const props = [
    {
      name: languageCtx.getStringFromId("Invites"),
      handler: (p) => p.inviteCount,
    },
    {
      name: languageCtx.getStringFromId("Photos/videos"),
      handler: (p) => p.mediaCount,
    },
    {
      name: languageCtx.getStringFromId("Max minutes per video"),
      handler: (p) => p.maxSecondsPerVideo / 60,
    },
    {
      name: languageCtx.getStringFromId("duration"),
      handler: (p) =>
        stringUtil.formatString(
          languageCtx.getStringFromId("X days"),
          p.dayCount
        ),
    },
    {
      name: languageCtx.getStringFromId("Price"),
      bold: true,
      handler: (p) => {
        return p.priceAmountMicros > 0
          ? `${albumUtil.price.fromMillis(p.priceAmountMicros).toFixed(2)} ${
              p.currencyCode
            }`
          : languageCtx.getStringFromId("free");
      },
    },
  ];

  const preItems = [
    {
      onRender: languageCtx.getStringFromId("What is it title"),
      onClick: () => handleScroll("#whatisit"),
      isVisible: () => true,
    },
    {
      onRender: languageCtx.getStringFromId("How does it work title"),
      onClick: () => handleScroll("#howitworks"),
      isVisible: () => true,
    },
    {
      onRender: languageCtx.getStringFromId("Prices"),
      onClick: () => handleScroll("#prices"),
      isVisible: () => true,
    },
    {
      onRender: languageCtx.getStringFromId("Help"),
      onClick: () => setShowHelpModal(true),
      isVisible: () => true,
    },
  ];

  const postItems = [
    {
      onRender: languageCtx.getStringFromId("Go to application"),
      onClick: () => navigate("/app"),
      isVisible: () => true,
      className: "btn btn-blue mx-1",
      type: "button",
    },
  ];

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={languageCtx.getStringFromId("What is it description")}
        />
        <meta
          name="og:description"
          content={languageCtx.getStringFromId("shortdescription")}
        />
      </Helmet>
      <HeaderControl
        preItems={preItems}
        postItems={postItems}
        language={true}
      />
      <div className="page align-center">
        <section className="main-banner">
          <div className="main-banner-overlay"></div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <h1 className="mb-3 display-2 fw-700">
                  {languageCtx.getStringFromId("Title")}
                </h1>

                <p className="lh-base fs-5">
                  {languageCtx.getStringFromId("Punchline")}
                </p>
                <p>
                  <Link className="btn btn-blue btn-lg mx-1" to="/app">
                    {languageCtx.getStringFromId("Start now")}
                  </Link>
                  <Link
                    className="btn btn-green btn-lg mx-1"
                    to="/app/joindemo"
                  >
                    {languageCtx.getStringFromId("Try our demo")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section" id="whatisit">
          <div className="container">
            <div className="row justify-content-center">
              <div className="section-title mt-5">
                {languageCtx.getStringFromId("What is it title")}
              </div>
              <div className="section-description mt-5">
                {languageCtx.getStringFromId("What is it description")}
              </div>
            </div>
            <div className="section-description mt-5">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="me-2"
                />
                {languageCtx.getStringFromId("No app installation required")}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="me-2"
                />
                {languageCtx.getStringFromId(
                  "No registration needed to join an album"
                )}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="me-2"
                />
                {languageCtx.getStringFromId("Easy to share photos and videos")}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="me-2"
                />
                {languageCtx.getStringFromId(
                  "Photos and videos are stored in original quality"
                )}
              </div>
            </div>

            <div className="mt-5 section-description">
              {languageCtx.getStringFromId(
                "Fotisima works on both Apple and Android devices..."
              )}
            </div>
          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title mt-5 mb-5">
                  {languageCtx.getStringFromId("How does it work title")}
                </div>
              </div>
              {howDoesItWorkTopics.map((t, index) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3" key={index}>
                    <div className="p-4 position-relative">
                      <div
                        className={`step-number-container ${
                          index && "step-number-arrow"
                        }`}
                      >
                        <div className="step-number">{index + 1}</div>
                      </div>

                      <div className="step-title mt-4">
                        <strong>{t.title}</strong>
                      </div>
                      <div className="step-description mt-2 ">
                        {t.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="section" id="prices">
          <div className="container">
            <div className="row section-description">
              <div className="col-12">
                <div className="section-title mt-5 mb-5">
                  {languageCtx.getStringFromId("Prices")}
                </div>
              </div>
              <div className="col-12">
                {languageCtx.getStringFromId(
                  "Nobody knows the size of your event better than you. Choose a size that fits your event and invite as many people as you want."
                )}
              </div>

              <CheckButtons
                theme="blue"
                items={packages.map((p) => ({
                  text: languageCtx.getPackageFromId(p.productId).name, // packageId
                }))}
                selectedIndex={selectedIndex}
                style={{ marginHorizontal: 20, marginTop: 30 }}
                setSelectedIndex={setSelectedIndex}
              />

              <div className="col-12 p-2 mt-3">
                <div style={{ borderRadius: 10, border: "1px solid #e6e6e6" }}>
                  <div className="p-3">
                    <div className="fs-1 fw-bold">
                      {languageCtx.getPackageFromId(_package.productId).name}
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold">
                        {languageCtx.getStringFromId("Invites")}
                      </div>
                      <div>{_package.inviteCount}</div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold">
                        {languageCtx.getStringFromId("Photos/videos")}
                      </div>
                      <div>{_package.mediaCount}</div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold">
                        {languageCtx.getStringFromId("Max minutes per video")}
                      </div>
                      <div>{_package.maxSecondsPerVideo / 60}</div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold">
                        {languageCtx.getStringFromId("duration")}
                      </div>
                      <div>
                        {stringUtil.formatString(
                          languageCtx.getStringFromId("X days"),
                          _package.dayCount
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="fs-4 fw-bold">
                        {_package.priceAmountMicros > 0
                          ? `${albumUtil.price
                              .fromMillis(_package.priceAmountMicros)
                              .toFixed(2)} ${_package.currencyCode}`
                          : languageCtx.getStringFromId("free")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3">
                <a href="" onClick={handleCompareClicked}>
                  {languageCtx.getStringFromId("Compare packages")}
                </a>
              </div>
              <div className="col-12 mt-3">
                {languageCtx.getStringFromId(
                  "Have a huge event or want to join our affiliate program"
                )}
                :{" "}
                <a href={`mailto:${settings.company.emails.helpdesk}`}>
                  {languageCtx.getStringFromId("Contact us")}
                </a>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={showCompareModal}
          setShow={setShowCompareModal}
          closeButton={true}
          size="lg"
          title={languageCtx.getStringFromId("Packages")}
        >
          <div className="p-4">
            <div className="table-responsive">
              <table className="table table-striped border-light">
                <thead>
                  <tr>
                    <th></th>
                    {packages.map((p) => (
                      <th className="text-center" key={p._id}>
                        {languageCtx.getPackageFromId(p.productId).name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {props.map((row) => {
                    return (
                      <tr key={row.name}>
                        <th>{row.name}</th>
                        {packages.map((p) => {
                          if (row.bold)
                            return (
                              <th className="text-center" key={p._id}>
                                {row.handler(p)}
                              </th>
                            );
                          else
                            return (
                              <td className="text-center" key={p._id}>
                                {row.handler(p)}
                              </td>
                            );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        <Modal
          show={showHelpModal}
          setShow={setShowHelpModal}
          closeButton={true}
          size="lg"
          title={languageCtx.getStringFromId("Help")}
        >
          <HelpControl />
        </Modal>
      </div>

      <FooterControl />
    </div>
  );
};
