import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";
import { useMessage } from "../../../../../../../message.context";

import { useAppState } from "../../../../../appState.context";

export default ({ ctx }) => {
  const exceptionCtx = useException();
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const messageCtx = useMessage();

  const handleNoClicked = () => {
    ctx.popItem();
  };

  const handleYesClicked = async () => {
    try {
      ctx.setIsWorking(true);
      await appStateCtx.user.delete();

      messageCtx.showSuccess(languageCtx.getStringFromId("Account deleted"));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      ctx.setIsWorking(false);
    }
  };

  return (
    <div className="p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId(
          "Are you sure you want to delete account?"
        )}
      </div>
      <div className="mt-3 text-center">
        <button className="btn btn-gray" onClick={handleNoClicked}>
          {languageCtx.getStringFromId("no")}
        </button>
        <button className="btn btn-blue ms-1" onClick={handleYesClicked}>
          {languageCtx.getStringFromId("yes")}
        </button>
      </div>
    </div>
  );
};
