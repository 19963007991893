import { useEffect, useState } from "react";

import { useLanguage } from "../../../../../../language.context";
import { useAppState } from "../../../../appState.context";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [termsOfService, setTermsOfService] = useState();

  const refresh = async () => {
    try {
      const termsOfService =
        await appStateCtx.apiServerClient.termsOfService.getById(
          appStateCtx.user.get().termsOfService._id
        );

      setTermsOfService(termsOfService);
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  if (!termsOfService) return null;

  return (
    <div className="p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId("Fotisima and the service")}
      </div>
      <div className="mt-3">
        {languageCtx.getStringFromId(
          "Here you will find what Fotisima provides and"
        )}{" "}
        <a
          href={termsOfService.url}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {languageCtx.getStringFromId("TermsOfService")}
        </a>
      </div>
    </div>
  );
};
