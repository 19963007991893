import { useEffect, useRef, useState } from "react";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";
import SpinnerControl from "../../../../../../controls/spinner.control";

import { useAppState } from "../../../../appState.context";

import SelectCoverControl from "./selectCover.control";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const btnNextRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const isFormOk = () => {
    if (!ctx.createAlbum.packages.length) return false;
    if (!ctx.createAlbum.name) return false;
    if (ctx.createAlbum.name.length < 3) return false;

    return true;
  };

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);

        if (ctx.createAlbum.packages.length > 0) return;

        const store =
          await appStateCtx.apiServerClient.package.getStoreByIPLocation();

        const packages =
          await appStateCtx.apiServerClient.package.getAllByCurrencyCode(
            store.currencyCode
          );

        packages.sort((a, b) => a.priceAmountMicros - b.priceAmountMicros);

        ctx.createAlbum.setStore(store);
        ctx.createAlbum.setPackages(packages);
        if (packages.length > 0) {
          ctx.createAlbum.setPackage(packages[0]);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  const handleContinuePressed = () => {
    ctx.pushItem(languageCtx.getIdFromId("Create album"), (ctx) => (
      <SelectCoverControl ctx={ctx} />
    ));
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <SpinnerControl />
      </div>
    );
  }

  return (
    <div className="text-center p-4">
      <div>
        <div className="fs-2 lh-sm">
          {languageCtx.getStringFromId(
            "letsGetStartedWhatNameWouldYouLikeForYourNewAlbum"
          )}
        </div>
        <div className="mt-3">
          <input
            className="form-control text-center form-control-lg"
            value={ctx.createAlbum.name}
            onChange={(e) => ctx.createAlbum.setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.which === 13) {
                e.preventDefault();
                btnNextRef.current.click();
              }
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <button
          ref={btnNextRef}
          onClick={handleContinuePressed}
          className="btn btn-blue"
          disabled={!isFormOk()}
        >
          {languageCtx.getStringFromId("continue")}
        </button>
      </div>
    </div>
  );
};
