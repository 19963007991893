import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import validateUtil from "../../../../utils/validate.util";
import stringUtil from "../../../../utils/string.util";

import { useLanguage } from "../../../language.context";
import { useMessage } from "../../../message.context";
import { useException } from "../../../exception.context";
import CodeControl from "../../../controls/code.control2";

import { useAppState } from "../appState.context";

import { useAuth } from "./context";

export default () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const languageCtx = useLanguage();
  const messageCtx = useMessage();
  const authCtx = useAuth();

  const [isWorking, setIsWorking] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (!authCtx.email) {
      navigate("../enteremail", { replace: true });
    }
  }, []);

  const handleSubmitPressed = async () => {
    try {
      setIsWorking(true);
      if (authCtx.processType === "signin") {
        const { user, album } =
          await appStateCtx.user.signinByEmailAndVerificationCode(
            authCtx.email,
            verificationCode
          );

        messageCtx.showSuccess(
          stringUtil.formatString(
            languageCtx.getStringFromId("Welcome xxx"),
            user.name
          )
        );

        if (album) {
          navigate(`/app/albums/${album._id}`);
        } else {
          navigate(`/app/albums`);
        }
      } else {
        const { user } = await appStateCtx.user.signup(
          authCtx.name,
          authCtx.email,
          verificationCode,
          authCtx.privacyPolicy._id,
          authCtx.termsOfService._id
        );

        messageCtx.showSuccess(
          stringUtil.formatString(
            languageCtx.getStringFromId("Welcome xxx"),
            user.name
          )
        );
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (
      !validateUtil.string.isValid(verificationCode) ||
      verificationCode.length !== 4
    )
      return false;

    return true;
  };

  const setVerificationCodeAdapter = (code) => {
    const code2 = code.replace(/\D/g, "").toUpperCase();
    setVerificationCode(code2);
  };

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container">
        <div className="fs-2">
          {languageCtx.getStringFromId(
            "enterTheVertificationCodeSentToYourEmail"
          )}
        </div>
        <div className="mt-3">
          <CodeControl
            inputMode="numeric"
            onSubmit={handleSubmitPressed}
            value={verificationCode}
            onChange={setVerificationCodeAdapter}
            forceUppercase={true}
            filterChars={"0123456789".split("")}
            filterCharsIsWhitelist={true}
            fields={4}
            autoFocus={true}
            inputStyle={{
              padding: 0,
              marginLeft: 2,
              marginRight: 2,
              maxWidth: 65,
              maxHeight: 65,
              textAlign: "center",
              fontSize: 40,
              fontWeight: 700,
            }}
          />
        </div>

        <div className="color-white mt-4">
          {`${languageCtx.getStringFromId("tip")}: ${stringUtil.formatString(
            languageCtx.getStringFromId("checkYourEmailX"),
            authCtx.email
          )}`}
        </div>

        <div className="mt-3">
          <button
            id="submit"
            disabled={!isFormOk() || isWorking}
            className="btn btn-lg btn-black"
            onClick={handleSubmitPressed}
          >
            {languageCtx.getStringFromId("continue")}
          </button>
        </div>
      </div>
    </div>
  );
};
