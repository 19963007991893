import { useLanguage } from "../../../../../../language.context";

import SelectStartDateControl from "./selectStartDate.control";
import SelectCoverFromLibraryControl from "./selectCoverFromLibrary.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const isFormOk = () => {
    if (!ctx.createAlbum.cover) return false;

    return true;
  };

  const handleContinuePressed = () => {
    ctx.pushItem(languageCtx.getIdFromId("Create album"), (ctx) => (
      <SelectStartDateControl ctx={ctx} />
    ));
  };

  const handleSelectCoverClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Select from library"), (ctx) => (
      <SelectCoverFromLibraryControl
        ctx={ctx}
        cover={ctx.createAlbum.cover}
        setCover={ctx.createAlbum.setCover}
      />
    ));
  };

  return (
    <div className="text-center p-4">
      <div>
        <div className="fs-2 lh-sm">
          {languageCtx.getStringFromId("whatCoverWouldYouLikeForYourAlbum")}
        </div>

        <div
          className="mt-3 cover clickable"
          onClick={handleSelectCoverClicked}
        >
          {ctx.createAlbum.cover && (
            <img
              src={ctx.createAlbum.cover.uri}
              style={{
                width: "100%",
                height: "100%",
                objectFit:
                  ctx.createAlbum.cover?.source === "db" ? "contain" : "cover",
              }}
            />
          )}
          {!ctx.createAlbum.cover && (
            <div className="user-select-none">
              {languageCtx.getStringFromId("selectCover")}
            </div>
          )}
        </div>

        <div className="mt-3">
          {`${languageCtx.getStringFromId(
            "tip"
          )}: ${languageCtx.getStringFromId(
            "chooseAPhotoSoYourFriendsCanRecognizeYourAlbum"
          )}`}
        </div>
      </div>

      <button
        disabled={!isFormOk()}
        className="btn btn-blue mt-3"
        onClick={handleContinuePressed}
      >
        {languageCtx.getStringFromId("continue")}
      </button>
    </div>
  );
};
