import { Routes, Route, Navigate } from "react-router-dom";

import MainPage from "./main.page";

import InviteCardsScreen from "./invitecards.page";
import App from "./app/app";
import AffiliateApp from "./affiliate/app";

export default () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<MainPage />} />
          <Route path="invitecards/:albumId" element={<InviteCardsScreen />} />
          <Route path="app/*" element={<App />} />
          <Route path="affiliate/*" element={<AffiliateApp />} />
          <Route
            path="privacypolicy"
            element={<Navigate to="/app/privacypolicy" />}
          />
          <Route
            path="termsofservice"
            element={<Navigate to="/app/termsofservice" />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
