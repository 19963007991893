export class LocalError extends Error {
  constructor(error, extra) {
    super(error.id);
    this.error = error;
    this.extra = extra;
  }
}

export const errors = {
  BadConnection: {
    id: "LOCAL_BAD_CONNECTION",
  },
  FileInvalid: {
    id: "LOCAL_FILE_INVALID",
  },
  VideoTooLong: {
    id: "LOCAL_VIDEO_TOO_LONG",
  },
};
