import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import ApiServerAffiliateClient from "../../../utils/apiserver.affiliate.client";
import localStorageUtil from "../../../utils/localStorage.util";
import errorUtil from "../../../utils/error.util";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";
import HeaderControl from "../../controls/header2.control";

const apiServerClient = new ApiServerAffiliateClient();
const appContext = createContext();

export const useAffiliateAppState = () => {
  return useContext(appContext);
};

export const AffiliateAppStateContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [affiliate, setAffiliate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleException = (ex) => {
      if (errorUtil.isCustomError(ex)) {
        if (ex.error.id === "SERVER_AFFILIATE_NOT_AUTHENTICATED") {
          signout();
        }
      }
    };

    exceptionCtx.addHandler(handleException);

    return () => exceptionCtx.removeHandler(handleException);
  });

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);
        const token = loadToken();

        const affiliate = await loadAffiliateFromToken(token);
        setAffiliateProxy(affiliate);
      } catch (ex) {
        //exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (affiliate && languageCtx.language) {
          if (languageCtx.language.code !== affiliate.language) {
            const affiliate = await apiServerClient.affiliate.updateLanguage(
              languageCtx.language.code
            );

            setAffiliate(affiliate);
          }
        }
      } catch (ex) {}
    };
    refresh();
  }, [languageCtx.language]);

  const setAffiliateProxy = (affiliate) => {
    saveToken(affiliate?.token);
    setAffiliate(affiliate);
  };

  // user

  const loadAffiliateFromToken = async (token) => {
    if (!token) {
      return null;
    }

    const affiliate = await apiServerClient.affiliate.signinByToken(
      token,
      languageCtx.language.code
    );

    return affiliate;
  };

  const loadToken = () => {
    try {
      const token = localStorageUtil.get("affiliatetoken");

      return token;
    } catch (ex) {
      return null;
    }
  };

  const saveToken = (token) => {
    if (!token) {
      localStorageUtil.remove("affiliatetoken");
    } else {
      localStorageUtil.set("affiliatetoken", token);
    }
  };

  const signin = async (email, password) => {
    const affiliate = await apiServerClient.affiliate.signin(
      email,
      password,
      languageCtx.language.code
    );

    setAffiliateProxy(affiliate);

    return affiliate;
  };

  const signout = () => {
    setAffiliateProxy();
  };

  const initialValue = {
    affiliate: {
      get: () => affiliate,
      signin,
    },
    apiServerClient,
  };

  const handleLogoutClicked = () => {
    signout();
  };

  if (isLoading) return null;

  const preItems = [
    {
      onRender: languageCtx.getStringFromId("Welcome"),
      onClick: () => navigate("/affiliate/welcome"),
      isVisible: () => !!affiliate,
    },
    {
      onRender: languageCtx.getStringFromId("Giftcards"),
      onClick: () => navigate("/affiliate/giftcards"),
      isVisible: () => !!affiliate,
    },
    {
      onRender: languageCtx.getStringFromId("Current month"),
      onClick: () => navigate("/affiliate/giftcards/charges"),
      isVisible: () => !!affiliate,
    },
    {
      onRender: languageCtx.getStringFromId("Invoices"),
      onClick: () => navigate("/affiliate/giftcards/invoices"),
      isVisible: () => !!affiliate,
    },
    {
      onRender: <FontAwesomeIcon icon={faUser} />,
      onClick: () => navigate("/affiliate/profile"),
      isVisible: () => !!affiliate,
      className: "pe-0",
    },
  ];

  const postItems = [
    {
      onRender: languageCtx.getStringFromId("logout"),
      onClick: handleLogoutClicked,
      isVisible: () => !!affiliate,
      className: "btn btn-blue",
      type: "button",
    },
  ];

  return (
    <>
      <HeaderControl
        preItems={preItems}
        postItems={postItems}
        language={true}
      />
      <appContext.Provider value={initialValue}>{children}</appContext.Provider>
    </>
  );
};
