import { useState } from "react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLanguage } from "../../../../../language.context";

import { useAppState } from "../../../appState.context";

import RecomendationsModal from "../recomendations.button/recomendations.modal/modal";
import { getItems } from "../recomendations.button/recomendations.modal/control";

export default () => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [showRecomendationsModal, setShowRecomendationsModal] = useState(false);

  const handleNotificationsClicked = () => {
    setShowRecomendationsModal(true);
  };

  const items = getItems(languageCtx, appStateCtx);

  return (
    <>
      {items.some((a) => a.visible()) && (
        <button
          className="btn btn-primary-warning d-flex justify-content-center align-items-center"
          style={{ borderRadius: 100, width: 40, height: 40 }}
          onClick={handleNotificationsClicked}
        >
          <FontAwesomeIcon icon={faBell} size="lg" />
        </button>
      )}
      <RecomendationsModal
        show={showRecomendationsModal}
        setShow={setShowRecomendationsModal}
      />
    </>
  );
};
