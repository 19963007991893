import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import settings from "../../utils/settings";

import { useLanguage } from "../language.context";
import { useException } from "../exception.context";

import SpinnerControl from "./spinner.control";

const stripePromise = loadStripe(settings.stripe.publishableKey);

const CheckoutForm = ({
  price,
  currency,
  isLoading,
  isWorking,
  setIsWorking,
  paymentCompletionUrl,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const languageCtx = useLanguage();

  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsWorking?.(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: paymentCompletionUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(languageCtx.noTranslate(error.message));
    } else {
      setMessage(languageCtx.getStringFromId("An unexpected error occurred"));
    }

    setIsWorking?.(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <div className="text-center">
          <button
            disabled={isLoading || isWorking || !stripe || !elements}
            id="submit"
            className="btn btn-blue mt-3 d-inline"
          >
            <span id="button-text">
              {`${languageCtx.getStringFromId("pay")} ${price.toFixed(
                2
              )} ${currency}`}
            </span>
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && (
          <div className="mt-3 text-center">
            <div id="payment-message">{message}</div>
          </div>
        )}
      </form>
    </>
  );
};

export default ({
  onCreateClientSecret,
  price,
  currency,
  isWorking,
  setIsWorking,
  paymentCompletionUrl,
}) => {
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const refresh = async () => {
    try {
      setIsLoading(true);
      const clientSecret = await onCreateClientSecret();

      setClientSecret(clientSecret);
      setIsLoading(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
    locale: languageCtx.language.code,
  };

  return (
    <div className="text-center p-4">
      <div>
        <div className="fs-2">{languageCtx.getStringFromId("pay")}</div>
      </div>
      {isLoading && <SpinnerControl />}
      {!isLoading && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            price={price}
            currency={currency}
            isLoading={isLoading}
            isWorking={isWorking}
            setIsWorking={setIsWorking}
            paymentCompletionUrl={paymentCompletionUrl}
          />
        </Elements>
      )}
    </div>
  );
};
