import settings from "../../../../../../../utils/settings";

import PayControl from "../../../../../../controls/pay.control";

import { useAppState } from "../../../../appState.context";

export default ({ ctx }) => {
  const appStateCtx = useAppState();

  const handleCreateClientSecret = async () => {
    const clientSecret =
      await appStateCtx.apiServerClient.stripe.createCreateAlbumPaymentIntent(
        ctx.createAlbum.name,
        ctx.createAlbum.cover?._id || ctx.createAlbum.cover,
        ctx.createAlbum.startDate,
        ctx.createAlbum.timeZone,
        ctx.createAlbum.package._id
      );

    return clientSecret;
  };

  return (
    <PayControl
      onCreateClientSecret={handleCreateClientSecret}
      isWorking={ctx.isWorking}
      setIsWorking={ctx.setIsWorking}
      currency={ctx.createAlbum.package.currencyCode}
      price={ctx.createAlbum.package.priceAmountMicros / 1000000}
      paymentCompletionUrl={window.location.origin + "/app/payment"}
    />
  );
};
