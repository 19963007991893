import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../../../../../language.context";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import colors from "../../../../../../../utils/colors";

export default () => {
  const languageCtx = useLanguage();

  const handleVideoClicked = (video) => {
    window.open(video.videoUrl, "_blank").focus();
  };

  return (
    <div className="px-3">
      {/* <input className="form-control" placeholder="Search" /> */}
      <div>
        {languageCtx.getVideos().map((v) => {
          return (
            <div
              className="p-3 clickable settings-menu-item2 border rounded h-100 my-3"
              onClick={() => handleVideoClicked(v)}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  size="4x"
                  color={colors.blue}
                />
                <div className="d-flex flex-column ms-4">
                  <div>
                    <h5>{v.title}</h5>
                  </div>
                  <div>{v.description}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
