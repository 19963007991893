import React from "react";

import colors from "../../utils/colors";

const selectedContainerStyle = {
  backgroundColor: colors.darkblue,
  borderRadius: 40,
};

const selectedStyle = {
  fontWeight: "bold",
};

export default ({
  style,
  className,
  items,
  selectedIndex,
  setSelectedIndex,
}) => {
  return (
    <div style={style} className={className}>
      <div
        style={{
          backgroundColor: colors.blue,
          borderRadius: 40,
          display: "flex",
          flexDirection: "row",
          borderColor: colors.blue,
          borderWidth: 2,
          padding: 3,
        }}
      >
        {items.map((i, index) => {
          let containerStyle = {
            flex: 1,
            paddingTop: 5,
            paddingBottom: 5,
            justifyContent: "center",
          };
          const style = {
            textAlign: "center",
            color: colors.white,
          };

          if (index === selectedIndex) {
            Object.assign(containerStyle, selectedContainerStyle);
            Object.assign(style, selectedStyle);
          }

          return (
            <div
              key={index}
              onClick={() => setSelectedIndex(index)}
              style={{
                flex: 1,
                justifyContent: "center",
              }}
              className="clickable user-select-none"
            >
              <div style={containerStyle}>
                <div style={style}>{i.text}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
