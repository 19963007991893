import { AffiliateAppStateContextProvider } from "./appState.context";
import Nav from "./nav";

export default () => {
  return (
    <AffiliateAppStateContextProvider>
      <Nav />
    </AffiliateAppStateContextProvider>
  );
};
