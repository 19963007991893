import { useLocation } from "react-router-dom";
import { useLanguage } from "../language.context";
import { useEffect, useState } from "react";
import settings from "../../utils/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default () => {
  const languageCtx = useLanguage();

  const location = useLocation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    // execute on location change
    setCount(count + 1);
  }, [location]);

  return (
    <div>
      <section className="footer3 cid-sMBtLpPVNf ">
        <div className="container">
          <div className="media-container-row align-center mbr-white">
            <div className="row">
              <div className="fs-5">
                <a
                  href={settings.company.facebookUrl}
                  className="social"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href={settings.company.instagramUrl}
                  className="ms-2 social"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href={settings.company.youtubeUrl}
                  className="ms-2 social"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>{" "}
                -{" "}
                <a href={`mailto:${settings.company.emails.helpdesk}`}>
                  {languageCtx.getStringFromId("Contact us title")}
                </a>
              </div>
            </div>

            <div className="row mt-2">
              <div className="mbr-white align-center fs-5">
                {languageCtx.getStringFromId("Contact us description 1")}
                <a href={settings.company.web} className="text-primary">
                  {languageCtx.getStringFromId("Contact us description 2")}
                </a>
                {languageCtx.getStringFromId("Contact us description 3")}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
