import { useEffect, useState } from "react";
import { readAndCompressImage } from "browser-image-resizer";

import { useLanguage } from "../../../../../../language.context";
import { useException } from "../../../../../../exception.context";

import { useAppState } from "../../../../appState.context";

export default ({ ctx, cover, setCover }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const [covers, setCovers] = useState([]);

  const refresh = async () => {
    try {
      let covers = await appStateCtx.apiServerClient.cover.getAll();

      covers = covers
        .filter((c) => c.enabled)
        .sort((a, b) => a.order - b.order);

      setCovers(covers);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleImageClicked = (c) => {
    setCover(c);
    ctx.popItem();
  };

  const handleChangeCover = (e) => {
    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      const config = {
        quality: 0.9,
        maxWidth: 400,
        maxHeight: 400,
        //mimeType: "image/png",
      };
      // we reduce image on client since server cannot accept large files
      readAndCompressImage(file, config).then((resizedImage) => {
        var reader = new FileReader();

        reader.onload = function (e) {
          resizedImage.uri = e.target.result;
          setCover(resizedImage);
          ctx.popItem();
        };

        reader.readAsDataURL(resizedImage);
      });
    }
  };

  return (
    <div className="p-4">
      <label className="btn btn-blue w-100" htmlFor="uploadCover">
        {languageCtx.getStringFromId("Select from library")}
        <input
          type="file"
          id="uploadCover"
          style={{ display: "none" }}
          accept=".png,.jpeg,.jpg"
          onChange={handleChangeCover}
          onClick={(e) => (e.target.value = "")}
        />
      </label>
      <div className="row mt-3">
        {covers.map((c) => (
          <div
            key={c._id}
            className="col-4 d-flex justify-content-center align-items-center"
            style={{
              padding: 10,
            }}
          >
            <img
              onClick={() => handleImageClicked(c)}
              src={c.uri}
              style={{
                backgroundColor: "white",
                maxWidth: 100,
                maxHeight: 100,
                width: "100%",
                height: "100%",
                objectFit: "contain",
                //resizeMode: "contain", // : "cover",
              }}
              className="clickable"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
