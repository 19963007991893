import {
  faAddressCard,
  faHouse,
  faImages,
} from "@fortawesome/free-solid-svg-icons";

import deviceUtil from "../../../../../../../utils/device.util";
import userUtil from "../../../../../../../utils/user.util";
import albumUtil from "../../../../../../../utils/album.util";

import { useLanguage } from "../../../../../../language.context";
import ButtonMenuControl from "../../../../../../controls/buttonmenu.control";

import { useAppState } from "../../../../appState.context";

import AddtohomescreenControl from "../../menu.model/app/addtohomescreen.control";
import EditAlbumPackageScreen from "../../menu.model/album/editPackage/edit.control";

import RegisteruserControl from "./enteremail.control";

export const getItems = (languageCtx, appStateCtx, ctx) => {
  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("Add to home screen"),
      subtitleLanguageStringId: languageCtx.getIdFromId("Easy access to app"),
      icon: faHouse,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Add to home screen"), (ctx) => (
          <AddtohomescreenControl ctx={ctx} />
        ));
      },
      visible: () =>
        deviceUtil.isMobileOrTablet &&
        !deviceUtil.isInWebAppiOS &&
        !deviceUtil.isInWebAppChrome &&
        !appStateCtx.hideAddToHomeScreenButton,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Register user"),
      subtitleLanguageStringId: languageCtx.getIdFromId(
        "Access from any device"
      ),
      icon: faAddressCard,
      onClick: () => {
        ctx.pushItem(
          languageCtx.getIdFromId("Register user"),
          (ctx) => <RegisteruserControl ctx={ctx} />,
          () => {
            ctx.registerUser.reset();
          }
        );
      },
      visible: () => !userUtil.isRegistered(appStateCtx.user.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("changePackage"),
      subtitleLanguageStringId: languageCtx.getIdFromId(
        "Album will expire soon"
      ),
      icon: faImages,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("changePackage"), (ctx) => (
          <EditAlbumPackageScreen ctx={ctx} />
        ));
      },
      visible: () =>
        !albumUtil.hasBiggestPackage(appStateCtx.album.get()) &&
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) &&
        appStateCtx.album.get().status.closesInLessThan3Days,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("changePackage"),
      subtitleLanguageStringId: languageCtx.getIdFromId("Album is almost full"),
      icon: faImages,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("changePackage"), (ctx) => (
          <EditAlbumPackageScreen ctx={ctx} />
        ));
      },
      visible: () =>
        !albumUtil.hasBiggestPackage(appStateCtx.album.get()) &&
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) &&
        !appStateCtx.album.get().status.closesInLessThan3Days &&
        albumUtil.isMediasAlmostFull(appStateCtx.album.get()),
    },
  ];

  return items;
};

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();

  const items = getItems(languageCtx, appStateCtx, ctx);

  return <ButtonMenuControl items={items} />;
};
