import albumUtil from "../../../../../../../utils/album.util";
import stringUtil from "../../../../../../../utils/string.util";

import { useLanguage } from "../../../../../../language.context";
import CheckButtons from "../../../../../../controls/checkbuttons.control";

import ConfirmControl from "./confirm.control";
import PaywithGiftcardControl from "./paywithGiftcard.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const isFormOk = () => {
    if (!ctx.createAlbum.packages.includes(ctx.createAlbum.package))
      return false;
    return true;
  };

  const handleContinueClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Create album"), (ctx) => (
      <ConfirmControl ctx={ctx} />
    ));
  };

  const index = ctx.createAlbum.packages.indexOf(ctx.createAlbum.package);

  const handleGiftcardClicked = () => {
    ctx.pushItem(
      "Gift card",
      (ctx) => <PaywithGiftcardControl ctx={ctx} />,
      () => {
        ctx.createAlbum.setGiftcardCode("");
        ctx.createAlbum.setPackage(ctx.createAlbum.packages[0]);
      }
    );
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId("whatAlbumSizeFitsYourEvent")}
      </div>

      <button
        className="btn btn-link mt-1"
        style={{ display: "inline" }}
        onClick={handleGiftcardClicked}
      >
        🎁 {languageCtx.getStringFromId("I have a giftcard")} 🎁
      </button>

      <CheckButtons
        theme="blue"
        items={ctx.createAlbum.packages.map((p) => ({
          text: languageCtx.getPackageFromId(p.productId).name,
        }))}
        className="mt-2"
        selectedIndex={index}
        style={{ marginHorizontal: 20 }}
        setSelectedIndex={(index) => {
          const pkg = ctx.createAlbum.packages[index];
          ctx.createAlbum.setPackage(pkg);
        }}
      />

      <div className="m-3 p-3 package">
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Invites")}
          </div>
          <div>{ctx.createAlbum.package.inviteCount}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Photos/videos")}
          </div>
          <div>{ctx.createAlbum.package.mediaCount}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Max minutes per video")}
          </div>
          <div>{ctx.createAlbum.package.maxSecondsPerVideo / 60}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("duration")}
          </div>
          <div>
            {stringUtil.formatString(
              languageCtx.getStringFromId("X days"),
              ctx.createAlbum.package.dayCount
            )}
          </div>
        </div>

        <div className="mt-3">
          <div className="fs-4 fw-bold ">
            {ctx.createAlbum.package.priceAmountMicros > 0
              ? `${albumUtil.price
                  .fromMillis(ctx.createAlbum.package.priceAmountMicros)
                  .toFixed(2)} ${ctx.createAlbum.package.currencyCode}`
              : languageCtx.getStringFromId("free")}
          </div>
        </div>
      </div>

      <div className="mt-4">
        {`${languageCtx.getStringFromId("tip")}: ${languageCtx.getStringFromId(
          "youCanChangeYourAlbumPackageLaterShouldYouRunOutOfInvitesSpaceOrDays"
        )}`}
      </div>

      <div>
        <button
          disabled={!isFormOk()}
          onClick={handleContinueClicked}
          className="btn btn-blue mt-3"
        >
          {languageCtx.getStringFromId("continue")}
        </button>
      </div>
    </div>
  );
};
