import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { useLanguage } from "../language.context";

export default ({ preItems = [], postItems = [], language = true }) => {
  const languageCtx = useLanguage();

  const location = useLocation();
  const [count, setCount] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // execute on location change
    setCount(count + 1);
  }, [location]);

  const handleLanguageClicked = async (language) => {
    await languageCtx.setLanguageAsync(language.code);
  };

  const handleItemClicked = (e, item) => {
    e.preventDefault();
    setIsExpanded(false);
    item.onClick();
  };

  const renderItem = (item, index) => {
    if (item.type === "button")
      return (
        <a
          href="#"
          key={index}
          onClick={(e) => handleItemClicked(e, item)}
          className={`${item.className}`}
        >
          {item.onRender}
        </a>
      );
    else {
      return (
        <Nav.Link
          key={index}
          onClick={(e) => handleItemClicked(e, item)}
          className={`fw-500 ${item.className}`}
        >
          {item.onRender}
        </Nav.Link>
      );
    }
  };

  return (
    <div>
      <Navbar
        fixed="top"
        expand="lg"
        className="menu bg-light"
        onToggle={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/assets/images/fotisima-v2.svg"
              style={{ height: "3.8rem" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto"></Nav>
            <Nav className="align-items-center">
              {preItems.map(
                (item, index) =>
                  item.isVisible() && (
                    <Nav.Link
                      key={index}
                      onClick={(e) => handleItemClicked(e, item)}
                      className={`fw-500 ${item.className}`}
                    >
                      {item.onRender}
                    </Nav.Link>
                  )
              )}

              {language && (
                <NavDropdown
                  title={<FontAwesomeIcon icon={faGlobe} />}
                  align="end"
                  className="e-caret-hide"
                  id="navbarScrollingDropdown"
                >
                  {languageCtx.languages.map((l) => {
                    return (
                      <NavDropdown.Item
                        key={l.code}
                        onClick={() => handleLanguageClicked(l)}
                      >
                        {l.description}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              )}
              {postItems.map(
                (item, index) => item.isVisible() && renderItem(item, index)
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
