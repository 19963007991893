import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./App.css";
import ErrorBoundaryFeature from "./errorboundary.feature";
import AppRequirementsCheckFeature from "./apprequirementscheck.feature";
import { LanguageContextProvider } from "./language.context";
import { MessageContextProvider } from "./message.context";
import { ExceptionContextProvider } from "./exception.context";
import Nav from "./pages/nav";

function App() {
  return (
    <ErrorBoundaryFeature>
      <HelmetProvider>
        <BrowserRouter>
          <LanguageContextProvider>
            <AppRequirementsCheckFeature>
              <MessageContextProvider>
                <ExceptionContextProvider>
                  <Nav />
                </ExceptionContextProvider>
              </MessageContextProvider>
            </AppRequirementsCheckFeature>
          </LanguageContextProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundaryFeature>
  );
}

export default App;
