import { useState } from "react";

import { useLanguage } from "../../../../../../../language.context";
import { useException } from "../../../../../../../exception.context";
import { useMessage } from "../../../../../../../message.context";

import { useAppState } from "../../../../../appState.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isWorking, setIsWorking] = useState(false);
  const [name, setName] = useState(appStateCtx.user.get().name);

  const handleSavePressed = async () => {
    try {
      setIsWorking(true);

      await appStateCtx.user.updateName(name);

      messageCtx.showSuccess(languageCtx.getStringFromId("Name changed"));
      ctx.popItem();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!name) return false;
    if (name.length < 3) return false;

    return true;
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2">
        {languageCtx.getStringFromId("Enter new name")}
      </div>

      <input
        className="form-control mt-3 text-center form-control-lg"
        placeholder={languageCtx.getStringFromId("name")}
        autoFocus={true}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="mt-4">
        <button
          className="btn btn-blue"
          onClick={handleSavePressed}
          disabled={!isFormOk()}
        >
          {languageCtx.getStringFromId("Save")}
        </button>
      </div>
    </div>
  );
};
