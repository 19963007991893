import { useMemo } from "react";

import colors from "../utils/colors";

import { useLanguage } from "./language.context";

const AppRequirementsCheckFeature = ({ children }) => {
  const languageCtx = useLanguage();

  const browserErrors = useMemo(() => {
    const errors = [];
    if (!("localStorage" in window)) {
      errors.push("Your browser does not support localStorage");
    }

    if (!("IntersectionObserver" in window)) {
      errors.push("Your browser does not support IntersectionObserver");
    }
    return errors;
  }, []);

  if (browserErrors.length) {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ flex: 1, backgroundColor: colors.blue, color: colors.white }}
      >
        <div className="fs-2 text-center mt-5">
          <div>
            {languageCtx.getStringFromId("Browser is too old to run Fotisima.")}
          </div>
          <div>
            {languageCtx.getStringFromId(
              "Change to another browser or update it."
            )}
          </div>
        </div>
      </div>
    );
  }
  return children;
};

export default AppRequirementsCheckFeature;
