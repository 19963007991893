const util = {
  isRegistered: (user) => {
    if (!user) return false;
    return !!user.email;
  },
  isUser: (user) => {
    return !!user;
  },
  // isGuest: (user) => {
  //   if (!user) return false;
  //   return user.type === "guest";
  // },
};

export default util;
