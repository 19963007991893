import { Routes, Route, Navigate } from "react-router-dom";

import SigninPage from "./signin.page";

export default () => {
  return (
    <Routes>
      <Route path="/auth" element={<SigninPage />} />
      <Route
        path="*"
        element={<Navigate to="/affiliate/auth" replace={true} />}
      />
    </Routes>
  );
};
