class FileServerClient {
  #url = undefined;
  #token = undefined;
  #language = "en";

  constructor(url, token) {
    this.#url = url + "/api/v1";
    this.#token = token;
  }

  setLanguage = (language) => {
    this.#language = language;
  };

  #checkStatus2 = async (res) => {
    if (res.status === 200) {
      const result = await res.json();
      return result;
    } else if (res.status === 500) {
      const error = await res.json();
      throw error;
    } else {
      throw new Error(res.statusText);
    }
  };

  #postMultipartWithProgress = async (
    path,
    authorization,
    data,
    onProgress
  ) => {
    const res = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => {
        onProgress(e.loaded / e.total);
      });
      xhr.addEventListener("load", () => {
        resolve({ status: xhr.status, responseText: xhr.responseText });
      });
      xhr.addEventListener("error", () => {
        reject({ status: 503, responseText: "File upload failed" });
      });
      xhr.addEventListener("abort", () => {
        reject({ status: 503, responseText: "File upload aborted" });
      });
      const url = `${this.#url}${path}`;
      //console.log(url);
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Accept-Language", this.#language);
      if (authorization) {
        xhr.setRequestHeader("Authorization", "Bearer " + this.#token);
      }
      xhr.send(data);
    });

    if (res.status === 200) {
      return JSON.parse(res.responseText);
    } else if (res.status === 500) {
      const error = JSON.parse(res.responseText);
      throw error;
    } else {
      throw new Error(res.responseText);
    }
  };

  #postMultipart = async (path, authorization, data) => {
    const options = {
      method: "POST",
      headers: {
        "Accept-Language": this.#language,
        //"Content-Type": "multipart/form-data",
      },
    };

    if (authorization) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = data;
    }

    const res = await fetch(`${this.#url}${path}`, options);

    const result = await this.#checkStatus2(res);

    return result;
  };

  media = {
    uploadFile: async (albumId, deviceId, file, serverId, processHandler) => {
      const data = {
        albumId,
        deviceId,
        serverId,
        lastModified: file.lastModified,
      };

      const payload = new FormData();
      payload.append("file", file);
      payload.append("data", JSON.stringify(data));

      const result = this.#postMultipartWithProgress(
        `/media/uploadFile`,
        true,
        payload,
        processHandler
      );

      return result;
    },
  };
}

export default FileServerClient;
