import { useLanguage } from "../../../../../language.context";

import EnteremailControl from "../recomendations.button/recomendations.modal/enteremail.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const handleNoClicked = () => {
    ctx.popItem();
  };

  const handleYesClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Register user"), (ctx) => (
      <EnteremailControl ctx={ctx} />
    ));
  };

  return (
    <div className="p-3 text-center">
      <div className="fs-2 mt-3">
        {languageCtx.getStringFromId(
          "You must be a registered user to create an album."
        )}
      </div>
      <div className="mt-3">
        {languageCtx.getStringFromId("Want to register now?")}
      </div>
      <div className="mt-3">
        <button className="btn btn-gray" onClick={handleNoClicked}>
          {languageCtx.getStringFromId("no")}
        </button>
        <button className="btn btn-blue ms-1" onClick={handleYesClicked}>
          {languageCtx.getStringFromId("yes")}
        </button>
      </div>
    </div>
  );
};
