import { useNavigate } from "react-router";
import { useLanguage } from "../language.context";

export const HeaderItemControl = ({ text }) => {
  return (
    <div>
      <div className="d-flex align-items-center settings-menu-headeritem">
        <div>{text}</div>
      </div>
    </div>
  );
};

export const SectionListItemControl = ({
  text,
  sub,
  icon,
  onClick,
  className,
  lastItem,
}) => {
  return (
    <div
      className={`d-flex align-items-center flex-row settings-menu-item clickable ${
        lastItem ? "" : "border-bottom"
      } ${className}`}
      onClick={onClick}
    >
      <div>
        <div
          style={{
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {text}
        </div>
        {sub && (
          <div
            style={{
              marginLeft: 10,
            }}
          >
            {sub}
          </div>
        )}
      </div>
    </div>
  );
};

export default ({ items }) => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();

  const handleItemPressed = (item) => {
    if (item.url) {
      navigate(item.url, item.params);
    } else if (item.onClick) {
      item.onClick();
    }
  };

  const items2 = items
    .map((i) => {
      return {
        ...i,
        data: i.data.filter((r) => !r.visible || r.visible()),
      };
    })
    .filter((i) => i.data.length > 0);

  return (
    <div>
      {items2.map((h) => (
        <div key={h.title} className="user-select-none">
          {!h.hidden && (
            <HeaderItemControl
              text={languageCtx.getStringFromId(h.title)}
              icon={h.icon}
            />
          )}
          <div>
            {h.data.map((item, index) => {
              return (
                <SectionListItemControl
                  key={item.id}
                  className={item.className}
                  text={item.name}
                  sub={item.sub}
                  icon={item.icon}
                  onClick={() => handleItemPressed(item)}
                  lastItem={index == h.data.length - 1}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
